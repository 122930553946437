var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "animated fadeIn form",
      staticStyle: {
        display: "flex",
        position: "relative",
        "max-height": "calc(100vh - 180px)",
        "min-height": "96%",
      },
    },
    [
      _vm.expenseItem
        ? _c(
            "b-row",
            { staticStyle: { "min-height": "95%", flex: "1" } },
            [
              _c(
                "b-col",
                { staticClass: "carousel-wrapper", attrs: { sm: "5" } },
                [
                  _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticStyle: { height: "100%" } },
                            [
                              _vm.expenseItem.images.length > 0
                                ? _c("carousel", {
                                    key: _vm.carouselImgUpd,
                                    staticStyle: {
                                      height: "100%",
                                      "justify-content": "space-between",
                                    },
                                    attrs: {
                                      value: [
                                        ..._vm.expenseItem.images,
                                      ].reverse(),
                                      "num-visible": _vm.numberItemVisible,
                                      "num-scroll": _vm.numberScroll,
                                      orientation: "horizontal",
                                      verticalViewPortHeight: `100%`,
                                      contentClass: "main-carousel",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "item",
                                          fn: function (slotProps) {
                                            return [
                                              _c(
                                                "div",
                                                { staticClass: "product-item" },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "product-item-content",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "parent cursor-pointer",
                                                        },
                                                        [
                                                          _vm.getFileExtension(
                                                            slotProps.data
                                                          ) !== "pdf"
                                                            ? _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "slide",
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-img-lazy",
                                                                    {
                                                                      staticClass:
                                                                        "px-1",
                                                                      staticStyle:
                                                                        {
                                                                          "border-radius":
                                                                            "8px",
                                                                          height:
                                                                            "100%",
                                                                        },
                                                                      attrs: {
                                                                        src: _vm.getImage(
                                                                          slotProps.data
                                                                        ),
                                                                        alt: slotProps.data,
                                                                        "fluid-grow":
                                                                          "",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "iframe-pdf",
                                                                },
                                                                [
                                                                  _c("iframe", {
                                                                    staticClass:
                                                                      "full full_h clearfix",
                                                                    attrs: {
                                                                      src: _vm.getImage(
                                                                        slotProps.data
                                                                      ),
                                                                      frameborder:
                                                                        "0",
                                                                    },
                                                                  }),
                                                                ]
                                                              ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "child" },
                                                [
                                                  _c(
                                                    "div",
                                                    { staticClass: "actions" },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "b-tooltip",
                                                              rawName:
                                                                "v-b-tooltip.hover.top",
                                                              value: {
                                                                variant:
                                                                  "primary",
                                                              },
                                                              expression:
                                                                "{ variant: 'primary' }",
                                                              modifiers: {
                                                                hover: true,
                                                                top: true,
                                                              },
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "button",
                                                            variant:
                                                              "transparent",
                                                            title: _vm.FormMSG(
                                                              61,
                                                              "Preview"
                                                            ),
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.showPicture(
                                                                slotProps
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "Eye"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              staticStyle: {
                                                                "margin-top":
                                                                  "4px",
                                                              },
                                                              attrs: {
                                                                size: 32,
                                                                "stroke-width": 2,
                                                                color:
                                                                  "#3f9b9b",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm.canEditExpense
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover.top",
                                                                  value: {
                                                                    variant:
                                                                      "danger",
                                                                  },
                                                                  expression:
                                                                    "{ variant: 'danger' }",
                                                                  modifiers: {
                                                                    hover: true,
                                                                    top: true,
                                                                  },
                                                                },
                                                              ],
                                                              attrs: {
                                                                type: "button",
                                                                variant:
                                                                  "transparent",
                                                                title:
                                                                  _vm.FormMSG(
                                                                    60,
                                                                    "Delete"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.removePicture(
                                                                      slotProps
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                _vm.getLucideIcon(
                                                                  "Trash2"
                                                                ),
                                                                {
                                                                  tag: "component",
                                                                  attrs: {
                                                                    size: 32,
                                                                    "stroke-width": 2,
                                                                    color:
                                                                      "#ea4e2c",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm.canEditExpense
                                                        ? _c(
                                                            "b-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover.top",
                                                                  value: {
                                                                    variant:
                                                                      "primary",
                                                                  },
                                                                  expression:
                                                                    "{ variant: 'primary' }",
                                                                  modifiers: {
                                                                    hover: true,
                                                                    top: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticClass:
                                                                "d-flex justify-content-center align-items-center",
                                                              style: `${
                                                                _vm.expenseItem
                                                                  .images
                                                                  .length > 1
                                                                  ? "margin: 12px 0 8px 0"
                                                                  : ""
                                                              }`,
                                                              attrs: {
                                                                variant:
                                                                  "transparent",
                                                                disabled:
                                                                  _vm.loadingImage,
                                                                title:
                                                                  _vm.FormMSG(
                                                                    45,
                                                                    "Add images"
                                                                  ),
                                                              },
                                                              on: {
                                                                click:
                                                                  _vm.onImageAdded,
                                                              },
                                                            },
                                                            [
                                                              _c("b-spinner", {
                                                                directives: [
                                                                  {
                                                                    name: "show",
                                                                    rawName:
                                                                      "v-show",
                                                                    value:
                                                                      _vm.loadingImage,
                                                                    expression:
                                                                      "loadingImage",
                                                                  },
                                                                ],
                                                                staticStyle: {
                                                                  "margin-bottom":
                                                                    "1px",
                                                                },
                                                                attrs: {
                                                                  small: "",
                                                                },
                                                              }),
                                                              !_vm.loadingImage
                                                                ? _c(
                                                                    _vm.getLucideIcon(
                                                                      "FilePlus"
                                                                    ),
                                                                    {
                                                                      tag: "component",
                                                                      attrs: {
                                                                        size: 32,
                                                                        "stroke-width": 2,
                                                                        color:
                                                                          "#3f9b9b",
                                                                      },
                                                                    }
                                                                  )
                                                                : _vm._e(),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4118347892
                                    ),
                                  })
                                : _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        height: "100%",
                                        width: "100%",
                                        "align-items": "center",
                                        "justify-content": "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "w-138-px d-flex justify-content-center align-items-center",
                                          style: `${
                                            _vm.expenseItem.images.length > 1
                                              ? "margin: 12px 0 8px 0"
                                              : ""
                                          }`,
                                          attrs: {
                                            size: "sm",
                                            variant: "outline-primary",
                                            disabled: _vm.loadingImage,
                                          },
                                          on: { click: _vm.onImageAdded },
                                        },
                                        [
                                          _c("b-spinner", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.loadingImage,
                                                expression: "loadingImage",
                                              },
                                            ],
                                            staticStyle: {
                                              "margin-bottom": "1px",
                                            },
                                            attrs: { small: "" },
                                          }),
                                          !_vm.loadingImage
                                            ? _c(
                                                _vm.getLucideIcon("PlusCircle"),
                                                {
                                                  tag: "component",
                                                  attrs: {
                                                    size: 16,
                                                    "stroke-width": 2,
                                                  },
                                                }
                                              )
                                            : _vm._e(),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "px-2",
                                              staticStyle: {
                                                "margin-bottom": "-2px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      45,
                                                      "Add images"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticStyle: {
                    overflow: "hidden auto",
                    "max-height": "calc(100vh - 226px)",
                  },
                  attrs: { sm: "7" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex w-100 flex-column align-items-center justify-content-center m-2",
                      staticStyle: { width: "fit-content" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start py-2",
                        },
                        [
                          _vm.totalItems > 1
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    ref: "chevronRightBack",
                                    staticClass:
                                      "btn-transparent text-color-rhapsody-in-blue",
                                    attrs: {
                                      disabled: _vm.item
                                        ? _vm.index === 0
                                        : true,
                                    },
                                    on: { click: _vm.back },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("ChevronLeft"), {
                                      tag: "component",
                                      attrs: { color: "rgba(6, 38, 62, 0.64)" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                          _c("div", {}, [
                            _vm.expenseId
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "text-color-rhapsody-in-blue fs-16 fw-40 text-bold",
                                    staticStyle: {
                                      color: "rgba(6, 38, 62, 0.74)",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(58, "Expense item") +
                                            " (#" +
                                            (_vm.item ? _vm.item.id : "") +
                                            ")" +
                                            "  " +
                                            (_vm.item ? _vm.index + 1 : 1) +
                                            " / " +
                                            _vm.totalItems
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm.totalItems > 1
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    ref: "chevronRightNext",
                                    staticClass:
                                      "btn-transparent text-color-rhapsody-in-blue",
                                    attrs: {
                                      disabled: _vm.item
                                        ? _vm.index + 1 === _vm.totalItems
                                        : true,
                                    },
                                    on: { click: _vm.next },
                                  },
                                  [
                                    _c(_vm.getLucideIcon("ChevronRight"), {
                                      tag: "component",
                                      attrs: { color: "rgba(6, 38, 62, 0.64)" },
                                    }),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "wrap-status justify-content-center d-flex",
                        },
                        [
                          _c(
                            "div",
                            {
                              class: `status ${
                                _vm.item ? _vm.item.statusClass : ""
                              }`,
                              staticStyle: {
                                "font-size": "0.95rem",
                                "letter-spacing": "0.1rem",
                              },
                            },
                            [
                              _vm._v(
                                "\n\t\t\t\t\t\t" +
                                  _vm._s(
                                    _vm.item ? _vm.item.validatedStatus : ""
                                  ) +
                                  "\n\t\t\t\t\t"
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm.expenseItem
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "3" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(1, "Date") } },
                                [
                                  _c("v-date-picker", {
                                    attrs: {
                                      "available-dates": {
                                        start: null,
                                        end: null,
                                      },
                                      locale: _vm.lang,
                                      masks: _vm.masks,
                                    },
                                    on: { dayclick: _vm.handleDate },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "default",
                                          fn: function ({
                                            inputValue,
                                            togglePopover,
                                          }) {
                                            return [
                                              _c(
                                                "b-input-group",
                                                [
                                                  _c("b-form-input", {
                                                    staticClass: "br-none",
                                                    attrs: {
                                                      value: inputValue,
                                                      readonly: "",
                                                      disabled:
                                                        !_vm.canEditExpense,
                                                    },
                                                  }),
                                                  _c(
                                                    "b-input-group-append",
                                                    [
                                                      _c(
                                                        "b-input-group-text",
                                                        {
                                                          staticClass:
                                                            "bg-color-white input-group-text-bg-white",
                                                          class: {
                                                            "cursor-pointer":
                                                              _vm.canEditExpense,
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              !_vm.canEditExpense,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              _vm.canEditExpense
                                                                ? togglePopover()
                                                                : ""
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            _vm.getLucideIcon(
                                                              "Calendar"
                                                            ),
                                                            {
                                                              tag: "component",
                                                              attrs: {
                                                                color:
                                                                  "rgba(6, 38, 62, .6)",
                                                                size: 18,
                                                                "stroke-width": 2.25,
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      3102800439
                                    ),
                                    model: {
                                      value: _vm.expenseItem.date,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.expenseItem, "date", $$v)
                                      },
                                      expression: "expenseItem.date",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c("department-selector", {
                                attrs: {
                                  "edit-data": _vm.expenseItem,
                                  "is-submitted": _vm.isSubmitted,
                                  "is-new": _vm.isNew,
                                  disabled: !_vm.canEditExpense,
                                  "department-desktop-size": "6",
                                  "category-desktop-size": "6",
                                  "hide-transportation-mean": "",
                                },
                                on: {
                                  change: _vm.handleDepartmentAndCatChange,
                                  "department:selector:invalid":
                                    _vm.isInvalidDeptCatFields,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "3" } },
                            [
                              _c(
                                "b-form-group",
                                [
                                  _c("label", [
                                    _vm._v("CO"),
                                    _c("sub", [_vm._v("2")]),
                                  ]),
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.rendKgCo2(
                                            _vm.expenseItem.kgCoTwo
                                          ),
                                          disabled: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c(_vm.getLucideIcon("Sprout"), {
                                                tag: "component",
                                                attrs: {
                                                  color: "#00A09C",
                                                  size: 20,
                                                  "stroke-width": 2.25,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.expenseItem.type === _vm.EXPENSE_TYPE.TRAVEL
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12" } },
                            [
                              _c("transport-type-selector", {
                                ref: "transportTypeRef",
                                attrs: {
                                  "data-additional-detail": _vm.isNil(
                                    _vm.expenseItem.additionalSubType
                                  )
                                    ? 0
                                    : _vm.expenseItem.additionalSubType,
                                  "data-detail": _vm.isNil(
                                    _vm.expenseItem.subType
                                  )
                                    ? 0
                                    : _vm.expenseItem.subType,
                                  "data-type": _vm.isNil(
                                    _vm.expenseItem.subCategory
                                  )
                                    ? 0
                                    : _vm.expenseItem.subCategory,
                                  "disable-form": !_vm.canEditExpense,
                                  "hide-boat-type": "",
                                  "hide-freight-type": "",
                                  "hide-menues-not-two-wheels": "",
                                },
                                on: {
                                  "transportation-type-selector:change":
                                    _vm.handleTransportationTypeSelectorChange,
                                  "transportation-type-selector:invalid":
                                    _vm.onTransportationTypeSelectorValidate,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          attrs: {
                            sm: `${String(
                              _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                                ? 6
                                : 12
                            )}`,
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            { attrs: { label: _vm.FormMSG(8, "Description") } },
                            [
                              _c("b-form-textarea", {
                                class: {
                                  "is-invalid":
                                    _vm.$v.expenseItem.description.$error,
                                },
                                staticStyle: { height: "7rem" },
                                attrs: { disabled: !_vm.canEditExpense },
                                model: {
                                  value: _vm.expenseItem.description,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.expenseItem,
                                      "description",
                                      $$v
                                    )
                                  },
                                  expression: "expenseItem.description",
                                },
                              }),
                              _vm.$v.expenseItem.description.$error
                                ? _c(
                                    "div",
                                    { staticClass: "invalid-feedback" },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              50,
                                              "Please, reference is required"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                        ? _c(
                            "b-col",
                            { attrs: { sm: "6" } },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c("supplier-selector", {
                                        attrs: {
                                          title: _vm.FormMSG(9, "Supplier"),
                                          label: _vm.FormMSG(10, "Supplier"),
                                          placeholder: _vm.FormMSG(
                                            11,
                                            "Type to search ..."
                                          ),
                                          "supplier-id":
                                            _vm.expenseItem.supplierId,
                                          "show-map": false,
                                          "ocr-suppliers": _vm.ocrImages,
                                          disabled: !_vm.canEditExpense,
                                          "hide-action": !_vm.canEditExpense,
                                          "use-new-design": "",
                                          version: "1.0",
                                        },
                                        on: {
                                          "supplier:unselected":
                                            _vm.onUnselected,
                                          change: _vm.handleSupplierChanged,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "mt-3" },
                                    [
                                      _c(
                                        "b-form-group",
                                        {
                                          attrs: {
                                            label: _vm.FormMSG(
                                              12,
                                              "Payment method"
                                            ),
                                          },
                                        },
                                        [
                                          _c("b-form-select", {
                                            attrs: {
                                              options: _vm.paymentTypeOptions,
                                              disabled: !_vm.canEditExpense,
                                            },
                                            model: {
                                              value:
                                                _vm.expenseItem.paiementType,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.expenseItem,
                                                  "paiementType",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "expenseItem.paiementType",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      16,
                                      "Total amount (tax incl.)"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        class:
                                          _vm.isInvalidAmount ||
                                          (_vm.$v.expenseItem &&
                                            _vm.$v.expenseItem.amountTotal &&
                                            _vm.$v.expenseItem.amountTotal
                                              .$error)
                                            ? "is-invalid"
                                            : "",
                                        attrs: {
                                          type: "number",
                                          step: "0.01",
                                          min: "0",
                                          placeholder: "0",
                                          disabled: !_vm.canEditExpense,
                                        },
                                        on: {
                                          input: _vm.handleAmountTotalChange,
                                        },
                                        model: {
                                          value: _vm.expenseItem.amountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.expenseItem,
                                              "amountTotal",
                                              $$v
                                            )
                                          },
                                          expression: "expenseItem.amountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm.isInvalidAmount ||
                                      (_vm.$v.expenseItem &&
                                        _vm.$v.expenseItem.amountTotal &&
                                        _vm.$v.expenseItem.amountTotal.$error)
                                        ? _c(
                                            "small",
                                            { staticStyle: { color: "red" } },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.isInvalidAmount
                                                      ? _vm.FormMSG(
                                                          51,
                                                          "Must be greater than VAT amount"
                                                        )
                                                      : _vm.$v.expenseItem &&
                                                        _vm.$v.expenseItem
                                                          .amountTotal.$error
                                                      ? _vm.FormMSG(
                                                          52,
                                                          "Please, value must be greater than 0"
                                                        )
                                                      : ""
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(14, "VAT Code") },
                                },
                                [
                                  _c("v-select", {
                                    ref: "flag-item",
                                    attrs: {
                                      label: "text",
                                      options: _vm.taxOptions,
                                      reduce: (option) => option.value,
                                      "get-option-label": (option) =>
                                        option.item ? option.item.factor : "",
                                      placeholder: "...",
                                      selectable: _vm.selectableItem,
                                      disabled: !_vm.canEditExpense,
                                    },
                                    on: { input: _vm.handleVatChange },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "option",
                                          fn: function (option) {
                                            return [
                                              option.disabled
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "text-bold",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.text)
                                                      ),
                                                    ]
                                                  )
                                                : _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "text-color ml-3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(option.text) +
                                                          " - " +
                                                          _vm._s(
                                                            option.item.name
                                                          ) +
                                                          " (" +
                                                          _vm._s(
                                                            option.item.factor
                                                          ) +
                                                          "%)"
                                                      ),
                                                    ]
                                                  ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1411684527
                                    ),
                                    model: {
                                      value: _vm.vatType,
                                      callback: function ($$v) {
                                        _vm.vatType = $$v
                                      },
                                      expression: "vatType",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(15, "VAT amount"),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        class: _vm.isInvalidAmount
                                          ? "is-invalid"
                                          : "",
                                        attrs: {
                                          max: _vm.expenseItem.amountTotal,
                                          type: "number",
                                          step: "0.01",
                                          min: "0",
                                          placeholder: "0",
                                          disabled:
                                            !_vm.canEditExpense ||
                                            _vm.vatAmountDisabled,
                                        },
                                        model: {
                                          value: _vm.expenseItem.amountVat,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.expenseItem,
                                              "amountVat",
                                              $$v
                                            )
                                          },
                                          expression: "expenseItem.amountVat",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.isInvalidAmount
                                    ? _c(
                                        "small",
                                        { staticStyle: { color: "red" } },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.FormMSG(
                                                53,
                                                "Must be smaller than total amount"
                                              )
                                            )
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "3" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      13,
                                      "Amount (tax excl.)"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          step: "0.01",
                                          min: "0",
                                          placeholder: "0",
                                          disabled: "",
                                        },
                                        model: {
                                          value: _vm.expenseItem.amount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.expenseItem,
                                              "amount",
                                              $$v
                                            )
                                          },
                                          expression: "expenseItem.amount",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.expenseItem.type === _vm.EXPENSE_TYPE.TRAVEL
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "12" } },
                            [
                              _c("google-distance", {
                                attrs: {
                                  "edit-data": _vm.expenseItem,
                                  "to-location-label": _vm.FormMSG(18, "To"),
                                  "from-location-label": _vm.FormMSG(
                                    17,
                                    "From"
                                  ),
                                  "to-location-error-message": _vm.FormMSG(
                                    29,
                                    "Arrival point, is required"
                                  ),
                                  "from-location-error-message": _vm.FormMSG(
                                    30,
                                    "Starting point, is required"
                                  ),
                                  "inline-validator": true,
                                  "is-submitted": _vm.isSubmitted,
                                  disabled: !_vm.canEditExpense,
                                  "distance-valid": _vm.expenseItem.km > 0,
                                },
                                on: {
                                  "google-distance:selector-from:invalid":
                                    _vm.isInvalidGoogleFrom,
                                  "google-distance:selector-to:invalid":
                                    _vm.isInvalidGoogleTo,
                                  "google-distance:loading":
                                    _vm.calculateLoading,
                                  change: _vm.handleDistanceChanges,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.expenseItem.type === _vm.EXPENSE_TYPE.TRAVEL
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(19, "Distance") },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        class: {
                                          "is-invalid":
                                            _vm.$v.expenseItem.km.$error,
                                        },
                                        attrs: {
                                          id: "expense-travel-distance",
                                          type: "number",
                                          placeholder: "0",
                                          min: "0",
                                          step: "0.001",
                                          readonly: _vm.loading,
                                          disabled: !_vm.canEditExpense,
                                        },
                                        model: {
                                          value: _vm.expenseItem.km,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.expenseItem, "km", $$v)
                                          },
                                          expression: "expenseItem.km",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c("b-input-group-text", [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "font-weight-bold",
                                                staticStyle: {
                                                  color: "#06263e",
                                                },
                                              },
                                              [_vm._v(_vm._s(_vm.distanceUnit))]
                                            ),
                                          ]),
                                        ],
                                        1
                                      ),
                                      _vm.$v.expenseItem.km.$error
                                        ? _c(
                                            "div",
                                            { staticClass: "invalid-feedback" },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    _vm.FormMSG(
                                                      62,
                                                      "Please, value must be greater than 0"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: `${
                                      _vm.FormMSG(20, "Rate") +
                                      " (" +
                                      _vm.distanceUnit +
                                      ")"
                                    }`,
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.expenseItem.kmRate,
                                          type: "number",
                                          step: "0.01",
                                          min: "0",
                                          placeholder: "0",
                                          readonly: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                { attrs: { label: _vm.FormMSG(21, "Amount") } },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          step: "0.01",
                                          min: "0",
                                          placeholder: "0",
                                          readonly: "",
                                        },
                                        model: {
                                          value: _vm.expenseItem.amountTotal,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.expenseItem,
                                              "amountTotal",
                                              $$v
                                            )
                                          },
                                          expression: "expenseItem.amountTotal",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.flags.length > 0
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "fieldset",
                              {
                                class: `${
                                  _vm.$screen.width <= 576
                                    ? "card-inside my-0 py-0 pb-0"
                                    : "scheduler-border"
                                }`,
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "text-color-rhapsody-in-blue-2",
                                    class: `${
                                      _vm.$screen.width <= 576
                                        ? "card-inside"
                                        : "scheduler-border"
                                    }`,
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(_vm.FormMSG(22, "Flags")) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-row",
                                  { staticClass: "mt-2 px-0" },
                                  _vm._l(_vm.flags, function (flag, index) {
                                    return _c(
                                      "b-col",
                                      {
                                        key: index,
                                        staticClass: "d-flex mb-2 w-100",
                                        attrs: {
                                          sm: _vm.sizeFlagInput(
                                            _vm.flags.length
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            staticClass: "w-100",
                                            attrs: {
                                              label: `${flag.name.toUpperCase()}`,
                                            },
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                label: "name",
                                                placeholder: "...",
                                                options: flag.projectFlagItem,
                                                reduce: (option) => option.id,
                                                disabled: !_vm.canEditExpense,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  return _vm.customFlagChanged(
                                                    $event,
                                                    flag.flagId
                                                  )
                                                },
                                              },
                                              model: {
                                                value: flag.customFlagSelected,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    flag,
                                                    "customFlagSelected",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "flag.customFlagSelected",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }),
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                    ? _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "fieldset",
                              {
                                class: `${
                                  _vm.$screen.width <= 576
                                    ? "card-inside my-0 py-0 pb-0"
                                    : "scheduler-border"
                                }`,
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "text-color-rhapsody-in-blue-2",
                                    class: `${
                                      _vm.$screen.width <= 576
                                        ? "card-inside"
                                        : "scheduler-border"
                                    }`,
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(23, "Green detail")
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "pt-2 pb-3 pl-2",
                                        attrs: { sm: "12" },
                                      },
                                      [
                                        _c("co-two-clap", {
                                          ref: "coTwoClapTicket",
                                          attrs: {
                                            "parent-id": +_vm.expenseItem.id,
                                            "entity-type": 1,
                                            "for-create": true,
                                            "external-carbon-data":
                                              _vm.carbonData,
                                            "disable-all": !_vm.canEditExpense,
                                            "hide-location-menu": "",
                                            "hide-premise-menu": "",
                                            "hide-btn-reviewed": "",
                                          },
                                          on: {
                                            "co-two-clap:change":
                                              _vm.handleCoTwoClapChange,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      )
                    : _c(
                        "b-row",
                        [
                          _c("b-col", [
                            _c(
                              "fieldset",
                              {
                                class: `${
                                  _vm.$screen.width <= 576
                                    ? "card-inside my-0 py-0 pb-0"
                                    : "scheduler-border"
                                }`,
                              },
                              [
                                _c(
                                  "legend",
                                  {
                                    staticClass:
                                      "text-color-rhapsody-in-blue-2",
                                    class: `${
                                      _vm.$screen.width <= 576
                                        ? "card-inside"
                                        : "scheduler-border"
                                    }`,
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(23, "Green detail")
                                        ) +
                                        "\n\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "pt-2 pb-3 pl-2",
                                        attrs: { sm: "12" },
                                      },
                                      [
                                        _c("co-two-clap", {
                                          ref: "coTwoClapTicket",
                                          attrs: {
                                            "parent-id": +_vm.expenseItem.id,
                                            "entity-type": 1,
                                            "for-create": false,
                                            "external-carbon-data":
                                              _vm.carbonData,
                                            "disable-all": _vm.canEditExpense,
                                            "hide-location-menu": "",
                                            "hide-premise-menu": "",
                                            "hide-btn-reviewed": "",
                                            "disable-carbon-type-selector": "",
                                            "hide-distance-known-transport": "",
                                            "hide-transport-type-selector-transport":
                                              "",
                                            "hide-from-address-transport": "",
                                            "hide-to-address-transport": "",
                                            "hide-distance-transport": "",
                                            "get-my-vehicule-in-transport": "",
                                          },
                                          on: {
                                            "co-two-clap:change":
                                              _vm.handleCoTwoClapChange,
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                ],
                1
              ),
              _c("capture-images", {
                ref: "capture-images",
                attrs: {
                  multiple: "",
                  "preview-mode": "button",
                  "parent-type": "expense_item",
                  "parent-sub-type": "ticket",
                  "parent-id": _vm.expenseItem.id,
                  pictures: _vm.rendImagesListForPreviewer,
                  "preview-modal-options": {
                    zIndex: 1041,
                    hideLikeDislike: true,
                    hideCommentButton: true,
                    hideDisLikeButton: true,
                  },
                  "dispatch-event-add-action": true,
                },
                on: {
                  change: _vm.handleImgsTaken,
                  "upload-image:loading": _vm.handleUploadImgStatus,
                },
              }),
              _c("reject-expense-item-modal", {
                ref: "reject-item-modal",
                attrs: {
                  id: _vm.expenseItem.id,
                  "type-name": _vm.EXPENSE_TREE_NAME.CHILDREN,
                },
                on: {
                  "reject-expense-item-modal:close":
                    _vm.onRejectExpenseModalClosed,
                  "reject-expense-item-modal:rejected":
                    _vm.onExpenseItemRejected,
                  "reject-expense-item-modal:redirectAndReloadExpenseDatatable":
                    _vm.onExpenseDatatableRedirectAndReloaded,
                },
                model: {
                  value: _vm.isRejectExpenseOpened,
                  callback: function ($$v) {
                    _vm.isRejectExpenseOpened = $$v
                  },
                  expression: "isRejectExpenseOpened",
                },
              }),
              _c("expense-splitting-modal", {
                attrs: {
                  "expense-id": +_vm.expenseId,
                  "expense-item": _vm.expenseItem,
                  index: _vm.index,
                  "selected-custom-flags": _vm.newValueCustomFlags,
                },
                on: {
                  "expense-splitting-modal:closed":
                    _vm.onExpenseSplittingClosed,
                  "expense-splitting-modal:reload":
                    _vm.onExpenseSplittingReloaded,
                },
                model: {
                  value: _vm.isExpenseSplittingOpen,
                  callback: function ($$v) {
                    _vm.isExpenseSplittingOpen = $$v
                  },
                  expression: "isExpenseSplittingOpen",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.expenseId && _vm.expenseItem
        ? _c(
            "b-row",
            {
              staticClass: "w-100 p-2 w-auto footer-expense-item",
              staticStyle: { padding: "12px 2px 8px 0 !important" },
            },
            [
              _c(
                "b-col",
                {
                  staticClass: "d-flex justify-content-end align-items-center",
                  staticStyle: {
                    "border-top": "0.005rem dotted rgba(226, 230, 235, 0.85)",
                  },
                },
                [
                  _vm.canEditExpense &&
                  (_vm.canMakeValidation ||
                    (_vm.canOnlyMakeSplitItem &&
                      (_vm.expenseItem.validated ===
                        _vm.VALIDATION_TYPE.VALIDATED_LEVEL_1 ||
                        _vm.expenseItem.validated ===
                          _vm.VALIDATION_TYPE.VALIDATED_LEVEL_2))) &&
                  _vm.expenseItem.type < _vm.EXPENSE_TYPE.TRAVEL
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "w-138-px d-flex justify-content-center align-items-center mr-3",
                          staticStyle: { "margin-top": "-1px" },
                          attrs: {
                            size: "sm",
                            variant: "custom-outline-blue",
                            block: "",
                          },
                          on: { click: _vm.splitExpenseItem },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.PLUS_CIRCLE.name), {
                            tag: "component",
                            staticClass: "mr-2",
                            attrs: { size: 18 },
                          }),
                          _c("div", { staticStyle: { "margin-top": "2px" } }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(57, "Split Item")) +
                                "\n\t\t\t\t"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  (_vm.expenseItem.validated ===
                    _vm.VALIDATION_TYPE.REFUSED_LEVEL_1 ||
                    _vm.expenseItem.validated ===
                      _vm.VALIDATION_TYPE.REFUSED_LEVEL_2) &&
                  _vm.canMakeValidation
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "w-138-px d-flex justify-content-center align-items-center mr-3",
                          attrs: {
                            size: "sm",
                            variant: "custom-outline-primary",
                            block: "",
                          },
                          on: { click: _vm.undoRejectExpenseItem },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.UNDO_2.name), {
                            tag: "component",
                            staticClass: "mr-2",
                            attrs: { size: 18 },
                          }),
                          _c("div", { staticStyle: { "margin-top": "2px" } }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(47, "Undo Rejection")) +
                                "\n\t\t\t\t"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm.expenseItem.validated !==
                        _vm.VALIDATION_TYPE.REFUSED_LEVEL_1 &&
                      _vm.expenseItem.validated !==
                        _vm.VALIDATION_TYPE.REFUSED_LEVEL_2 &&
                      _vm.canEditExpense &&
                      _vm.canMakeValidation
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "w-138-px d-flex justify-content-center align-items-center",
                          staticStyle: { "margin-top": "-2px" },
                          attrs: {
                            size: "sm",
                            variant: "outline-danger",
                            block: "",
                          },
                          on: { click: _vm.rejectExpenseItem },
                        },
                        [
                          _c(_vm.getLucideIcon(_vm.ICONS.X.name), {
                            tag: "component",
                            staticClass: "mr-2",
                            attrs: { size: 18 },
                          }),
                          _c("div", { staticStyle: { "margin-top": "2px" } }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(24, "Reject")) +
                                "\n\t\t\t\t"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.canEditExpense
                    ? _c(
                        "b-button",
                        {
                          staticClass:
                            "mx-3 w-138-px d-flex justify-content-center align-items-center",
                          staticStyle: { "margin-top": "-1px" },
                          attrs: {
                            size: "sm",
                            variant: "custom-primary-blue",
                            disabled: _vm.disableSaveBtn,
                            block: "",
                          },
                          on: { click: _vm.saveExpenseItem },
                        },
                        [
                          _c(_vm.getLucideIcon("Save"), {
                            tag: "component",
                            staticClass: "mr-2",
                            attrs: { size: 18 },
                          }),
                          _c("div", { staticStyle: { "margin-top": "2px" } }, [
                            _vm._v(
                              "\n\t\t\t\t\t" +
                                _vm._s(_vm.FormMSG(46, "Save")) +
                                "\n\t\t\t\t"
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass:
                        "w-138-px d-flex justify-content-center align-items-center",
                      staticStyle: { "margin-top": "-1px" },
                      attrs: {
                        size: "sm",
                        variant: "custom-outline-primary",
                        disabled: _vm.disableNext,
                        block: "",
                      },
                      on: { click: _vm.next },
                    },
                    [
                      _c(_vm.getLucideIcon("ChevronRight"), {
                        tag: "component",
                        staticClass: "mr-2",
                        attrs: { size: 18 },
                      }),
                      _c("div", { staticStyle: { "margin-top": "2px" } }, [
                        _vm._v(_vm._s(_vm.FormMSG(56, "Go next"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }