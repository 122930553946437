var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: "expenseCarouselView",
        "header-class": "header-class-modal-doc-package",
        size: "xxl",
        centered: "",
        "cancel-variant": "light",
        "no-close-on-backdrop": "",
        "no-close-on-esc": "",
        "hide-header-close": "",
      },
      on: { hidden: _vm.emitEventClose, show: _vm.prepareData },
      scopedSlots: _vm._u([
        {
          key: "modal-title",
          fn: function () {
            return [
              _c(
                "b-row",
                [
                  _c("b-col", { attrs: { cols: "4" } }, [
                    _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                      _vm._v(_vm._s(_vm.FormMSG(3, "Expense TVA Splitting"))),
                    ]),
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-center", attrs: { cols: "4" } },
                    [
                      _c("div", { staticStyle: { "font-size": "1.25rem" } }, [
                        _c("span", { staticClass: "text-uppercase" }, [
                          _vm._v(
                            _vm._s(
                              _vm.FormMSG(
                                4 + _vm.expenseItem.type,
                                _vm.EXPENSE_NAME[_vm.expenseItem.type]
                              )
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n\t\t\t\t\t#" +
                            _vm._s(_vm.expenseItem ? _vm.expenseItem.id : "") +
                            "\n\t\t\t\t"
                        ),
                      ]),
                    ]
                  ),
                  _c("b-col", { attrs: { cols: "4" } }),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "w-100 d-flex justify-content-end align-items-center",
                },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px",
                      staticStyle: { "margin-top": "0px" },
                      attrs: {
                        size: "sm",
                        variant: "outline-primary",
                        disabled: _vm.splittingItems.length === 1,
                        block: "",
                      },
                      on: { click: _vm.save },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(2, "Save")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "w-138-px ml-3",
                      attrs: {
                        size: "sm",
                        variant: "custom-outline-gray",
                        block: "",
                      },
                      on: { click: _vm.emitEventClose },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(_vm.FormMSG(1, "Close")) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.isOpen,
        callback: function ($$v) {
          _vm.isOpen = $$v
        },
        expression: "isOpen",
      },
    },
    [
      _c(
        "div",
        {
          class:
            _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
          staticStyle: { height: "100%" },
        },
        [
          _vm.expenseItem
            ? _c(
                "b-row",
                { staticClass: "form", staticStyle: { height: "100%" } },
                [
                  _c(
                    "b-col",
                    { staticClass: "carousel-wrapper", attrs: { sm: "5" } },
                    [
                      _vm.expenseItem.images.length > 0
                        ? _c("carousel", {
                            attrs: {
                              value: _vm.expenseItem.images,
                              "num-visible": _vm.numberItemVisible,
                              "num-scroll": _vm.numberScroll,
                              orientation: "horizontal",
                              verticalViewPortHeight: `${
                                _vm.$screen.height - 350
                              }px`,
                              contentClass: "main-carousel",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (slotProps) {
                                    return [
                                      _c(
                                        "div",
                                        { staticClass: "product-item" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "product-item-content",
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "parent cursor-pointer",
                                                },
                                                [
                                                  _vm.getFileExtension(
                                                    slotProps.data
                                                  ) !== "pdf"
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass: "slide",
                                                        },
                                                        [
                                                          _c("b-img-lazy", {
                                                            staticClass: "px-1",
                                                            staticStyle: {
                                                              "border-radius":
                                                                "8px",
                                                              height: "100%",
                                                            },
                                                            attrs: {
                                                              src: _vm.getImage(
                                                                slotProps.data
                                                              ),
                                                              alt: slotProps.data,
                                                              "fluid-grow": "",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      )
                                                    : _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "iframe-pdf",
                                                        },
                                                        [
                                                          _c("iframe", {
                                                            staticClass:
                                                              "full full_h clearfix",
                                                            attrs: {
                                                              src: _vm.getImage(
                                                                slotProps.data
                                                              ),
                                                              frameborder: "0",
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _c("div", { staticClass: "child" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "actions",
                                            staticStyle: {
                                              "margin-top": "32px",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                directives: [
                                                  {
                                                    name: "b-tooltip",
                                                    rawName:
                                                      "v-b-tooltip.hover.top",
                                                    value: {
                                                      variant: "primary",
                                                    },
                                                    expression:
                                                      "{ variant: 'primary' }",
                                                    modifiers: {
                                                      hover: true,
                                                      top: true,
                                                    },
                                                  },
                                                ],
                                                attrs: {
                                                  type: "button",
                                                  variant: "transparent",
                                                  title: _vm.FormMSG(
                                                    61,
                                                    "Preview"
                                                  ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showPicture(
                                                      slotProps
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(_vm.getLucideIcon("Eye"), {
                                                  tag: "component",
                                                  staticStyle: {
                                                    "margin-top": "4px",
                                                  },
                                                  attrs: {
                                                    size: 32,
                                                    "stroke-width": 2,
                                                    color: "#3f9b9b",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              216684713
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticClass: "pr-0", attrs: { sm: "7" } },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "sub-title pb-3",
                          staticStyle: { "margin-left": "0" },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(5, "Initial Expense item")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "px-3 pb-2" },
                        [
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(
                                      8,
                                      "Total amount (tax incl.)"
                                    ),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.expenseItem.amountTotal,
                                          type: "number",
                                          readonly: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: { label: _vm.FormMSG(7, "Total VAT") },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.expenseItem.amountVat,
                                          type: "number",
                                          readonly: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { attrs: { sm: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    label: _vm.FormMSG(6, "Amount (tax excl.)"),
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          value: _vm.expenseItem.amount,
                                          type: "number",
                                          readonly: "",
                                        },
                                      }),
                                      _c(
                                        "b-input-group-append",
                                        [
                                          _c(
                                            "b-input-group-text",
                                            [
                                              _c("currency-svg", {
                                                attrs: {
                                                  color: "#06263E",
                                                  opacity: "0.85",
                                                  width: "15",
                                                  height: "15",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "sub-title pb-3",
                          staticStyle: { "margin-left": "0" },
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" +
                              _vm._s(_vm.FormMSG(9, "New splitting")) +
                              "\n\t\t\t\t"
                          ),
                        ]
                      ),
                      _c(
                        "b-row",
                        { staticClass: "pb-2" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "div",
                                {
                                  staticStyle: {
                                    "text-align": "right",
                                    color: "rgba(6, 38, 62, 0.64)",
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fw-700",
                                      staticStyle: { padding: "0 30px 0 0" },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              11,
                                              "Note: Initial expense item will be splitted into different lines"
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticStyle: { "text-align": "right" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "fw-700",
                                      staticStyle: {
                                        padding: "0 30px 0 0",
                                        color: "red",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n\t\t\t\t\t\t\t\t" +
                                          _vm._s(
                                            _vm.FormMSG(
                                              49,
                                              'NB: Click inside the box, if you want to edit "Amount (tax excl.)"'
                                            )
                                          ) +
                                          "\n\t\t\t\t\t\t\t"
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    { staticClass: "px-5" },
                                    [
                                      _c(
                                        "b-table-simple",
                                        {
                                          attrs: {
                                            small: "",
                                            id: "expense-splitting",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-thead",
                                            {
                                              attrs: { "head-variant": "dark" },
                                            },
                                            [
                                              _c(
                                                "b-tr",
                                                {
                                                  staticClass: "text-center",
                                                  staticStyle: {
                                                    "line-height": "24px",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticStyle: {
                                                        border:
                                                          "none !important",
                                                        "background-color":
                                                          "transparent !important",
                                                        width: "6%",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticStyle: {
                                                        width: "25%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            15,
                                                            "Total amount (tax incl.)"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticStyle: {
                                                        width: "20.5%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            13,
                                                            "VAT Code"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticStyle: {
                                                        width: "22%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            14,
                                                            "VAT Amount"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticStyle: {
                                                        width: "21.5%",
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            12,
                                                            "Amount (tax excl.)"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticStyle: {
                                                        width: "5%",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-tbody",
                                            { staticClass: "text-right" },
                                            _vm._l(
                                              _vm.splittingItems,
                                              function (item, i) {
                                                return _c("item", {
                                                  key: i,
                                                  attrs: {
                                                    "expense-id":
                                                      +_vm.expenseId,
                                                    "expense-item-id":
                                                      +_vm.expenseItem.id,
                                                    index: i,
                                                    item: item,
                                                    nbRows:
                                                      _vm.splittingItems.length,
                                                  },
                                                  on: {
                                                    "expense-splitting-item:removed":
                                                      _vm.onLineSplittingRemoved,
                                                    "expense-splitting-item:updated":
                                                      _vm.onLineSplittingChanged,
                                                  },
                                                })
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "b-tfoot",
                                            { staticClass: "text-right" },
                                            [
                                              _c(
                                                "b-tr",
                                                {
                                                  staticStyle: {
                                                    "font-size": "1.1rem",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticClass: "text-left",
                                                      staticStyle: {
                                                        "border-top":
                                                          "0.005rem solid rgba(226, 230, 235, 0.85)",
                                                        "border-left":
                                                          "0.005rem solid rgba(226, 230, 235, 0.85)",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        { staticClass: "px-1" },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.FormMSG(
                                                                  16,
                                                                  "TOTAL"
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      style: `color: ${
                                                        _vm.globalValidation
                                                          .isValidAmountTotal
                                                          ? "#1f2021"
                                                          : "#EA4E2C"
                                                      }`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.currencyFormat(
                                                              _vm.global
                                                                .amountTotal
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      style: `color: ${
                                                        _vm.globalValidation
                                                          .isValidAmountVat
                                                          ? "#1f2021"
                                                          : "#EA4E2C"
                                                      }`,
                                                      attrs: { colspan: "2" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.currencyFormat(
                                                              _vm.global
                                                                .amountVat
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      style: `color: ${
                                                        _vm.globalValidation
                                                          .isValidAmount
                                                          ? "#1f2021"
                                                          : "#EA4E2C"
                                                      }`,
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n\t\t\t\t\t\t\t\t\t\t\t\t" +
                                                          _vm._s(
                                                            _vm.currencyFormat(
                                                              _vm.global.amount
                                                            )
                                                          ) +
                                                          "\n\t\t\t\t\t\t\t\t\t\t\t"
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-th",
                                                    {
                                                      staticStyle: {
                                                        "border-right": "none",
                                                        "border-bottom": "none",
                                                      },
                                                    },
                                                    [_vm._v(" ")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("b-row", { staticClass: "px-5" }, [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-content-end w-100" },
                          [
                            _c(
                              "b-button",
                              {
                                staticClass:
                                  "w-138-px d-flex justify-content-center align-items-center",
                                attrs: {
                                  size: "sm",
                                  variant: "outline-primary",
                                },
                                on: { click: _vm.addSplittingItem },
                              },
                              [
                                _c(_vm.getLucideIcon("PlusCircle"), {
                                  tag: "component",
                                  attrs: { size: 16, "stroke-width": 2 },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "px-2",
                                    staticStyle: { "margin-bottom": "-2px" },
                                  },
                                  [
                                    _vm._v(
                                      "\n\t\t\t\t\t\t\t\t" +
                                        _vm._s(
                                          _vm.FormMSG(10, "Add Splitting")
                                        ) +
                                        "\n\t\t\t\t\t\t\t"
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      staticClass: "modal-success",
                      attrs: {
                        id: "expense-splitting-warning-modal",
                        "header-class": "header-class-modal-doc-package",
                        title: _vm.FormMSG(17, "Warning"),
                        "header-bg-variant": "warning",
                        "header-border-variant": "warning",
                        "ok-variant": "light",
                        "ok-only": "",
                        centered: "",
                      },
                      on: {
                        ok: function ($event) {
                          _vm.warningModal = false
                        },
                      },
                      model: {
                        value: _vm.warningModal,
                        callback: function ($$v) {
                          _vm.warningModal = $$v
                        },
                        expression: "warningModal",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              18,
                              "Please fill (or correct the value of) the field(s) required (coloring red) or remove one (or all) of them."
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                  _c(
                    "b-modal",
                    {
                      staticClass: "modal-success",
                      attrs: {
                        id: "expense-splitting-error-modal",
                        "header-class": "header-class-modal-doc-package",
                        title: _vm.FormMSG(19, "Error"),
                        "header-bg-variant": "danger",
                        "header-border-variant": "danger",
                        "ok-variant": "light",
                        "ok-only": "",
                        centered: "",
                      },
                      on: {
                        ok: function ($event) {
                          _vm.errorModal = false
                        },
                      },
                      model: {
                        value: _vm.errorModal,
                        callback: function ($$v) {
                          _vm.errorModal = $$v
                        },
                        expression: "errorModal",
                      },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t\t" +
                          _vm._s(
                            _vm.FormMSG(
                              20,
                              "Please verify, some of amounts are not correct."
                            )
                          ) +
                          "\n\t\t\t"
                      ),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }