<template>
	<b-tr class="animated fadeIn" v-if="line">
		<b-td style="border: none !important">&nbsp;</b-td>
		<b-td>
			<b-form-group>
				<b-input-group>
					<b-form-input
						v-model="line.amountTotal"
						type="number"
						step="0.01"
						min="0"
						:class="{
							'is-invalid': isInvalid || $v.line.amountTotal.$error
						}"
						@keyup="handleInputChanged('amountTotal')"
					/>
					<b-input-group-append>
						<b-input-group-text>
							<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
		</b-td>
		<b-td class="text-center">
			<v-select
				v-model="line.vatId"
				label="text"
				:options="taxOptions"
				:reduce="(option) => option.value"
				:get-option-label="(option) => (option.item ? option.item.factor : '')"
				placeholder="..."
				:selectable="selectableItem"
				@input="handleInputChanged('vatId')"
			>
				<template #option="option">
					<div class="text-bold" v-if="option.disabled">{{ option.text }}</div>
					<div class="text-color ml-3" v-else>
						{{ option.text }} - <b>{{ option.item.name }} ({{ option.item.factor }}%)</b>
					</div>
				</template>
			</v-select>
		</b-td>
		<b-td>
			<b-form-group>
				<b-input-group>
					<b-form-input
						:class="isInvalid ? 'is-invalid' : ''"
						v-model="line.amountVat"
						type="number"
						step="0.01"
						min="0"
						:disabled="vatAmountDisabled"
						@keyup="handleInputChanged('amountVat')"
					/>
					<b-input-group-append>
						<b-input-group-text>
							<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
		</b-td>
		<b-td>
			<!-- <pre>{{ originalAmount }}</pre> -->
			<div v-b-tooltip.hover.right :title="FormMSG(1, 'Click to edit this amount')" @click="editAmount" v-if="!showInputAmout">
				{{ currencyFormat(line.amount) }}
			</div>
			<b-form-group v-else>
				<b-input-group>
					<b-form-input
						ref="amount"
						v-model="line.amount"
						type="number"
						:class="{
							'is-invalid': isInvalid || $v.line.amount.$error
						}"
						step="0.01"
						min="0"
						@blur="handleOutside"
						@keyup="handleInputChanged('amount')"
					/>
					<b-input-group-append>
						<b-input-group-text>
							<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
						</b-input-group-text>
					</b-input-group-append>
				</b-input-group>
			</b-form-group>
		</b-td>
		<b-td class="text-center">
			<component
				v-if="index > 0"
				:is="getLucideIcon(ICONS.TRASH.name)"
				:color="ICONS.TRASH.color"
				:stroke-width="2.5"
				:size="18"
				class="cursor-pointer"
				@click="removeItem"
			/>
		</b-td>
	</b-tr>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, decimal } from 'vuelidate/lib/validators';
import _ from 'lodash';
import { rendCurrency } from '~helpers';
import CurrencySvg from '@/components/Svg/Currency';
import { greaterThanZero } from '@/shared/utils';

export default {
	name: 'ExpenseSplittingItem',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		CurrencySvg
	},
	props: {
		item: {
			type: Object,
			required: false,
			default: () => {}
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		nbRows: {
			type: Number,
			required: false,
			default: 0
		}
	},
	computed: {
		...mapGetters({
			taxOptions: 'flag/taxOptions'
		}),
		isInvalid() {
			return parseFloat(this.line.amountTotal) < parseFloat(this.line.amountVat);
		}
	},
	data() {
		return {
			count: 0,
			line: null,
			vatAmountDisabled: false,
			showInputAmout: false,
			originalAmount: 0
		};
	},
	created() {
		this.$nextTick(async () => {
			await this.getTaxOptions();
		});
	},
	mounted() {
		this.originalAmount = this.line.amount;
	},
	methods: {
		...mapActions({
			getTaxOptions: 'flag/getTaxOptions'
		}),
		currencyFormat(value) {
			return rendCurrency(value);
		},
		selectableItem(item) {
			return !item.disabled;
		},
		handleInputChanged(fieldName) {
			if (fieldName === 'amountTotal' || fieldName === 'amountVat' || fieldName === 'amount') {
				this.line[fieldName] = +this.line[fieldName];
				if (isNaN(this.line[fieldName])) {
					this.line[fieldName] = 0;
				}
			}

			if (fieldName === 'vatId') {
				if (this.showInputAmout === false) {
					if (!_.isNil(this.line[fieldName])) {
						/**
						 * To let users to correct manually VAT Amount, let enable the field, for now
						 * so let's comment this code bellow
						 */
						// this.vatAmountDisabled = true;
						this.reCalculateVatAmountAndAmount(this.line[fieldName]);
					} else {
						/**
						 * To let users to correct manually VAT Amount, let enable the field, for now
						 * so let's comment this code bellow
						 */
						// this.vatAmountDisabled = false;
					}
				} else {
					if (!_.isNil(this.line[fieldName])) {
						/**
						 * To let users to correct manually VAT Amount, let enable the field, for now
						 * so let's comment this code bellow
						 */
						// this.vatAmountDisabled = true;
						this.reCalculateVatAmountAndTotalAmount(this.line[fieldName]);
					} else {
						/**
						 * To let users to correct manually VAT Amount, let enable the field, for now
						 * so let's comment this code bellow
						 */
						// this.vatAmountDisabled = false;
					}
				}
			} else if (fieldName === 'amountTotal') {
				this.showInputAmout = false;

				if (!_.isNil(this.line.vatId)) {
					this.reCalculateVatAmountAndAmount(this.line.vatId);
				} else {
					this.line.amount = this.line.amountTotal - this.line.amountVat;
				}

				this.originalAmount = this.line.amount;
			} else if (fieldName === 'amountVat') {
				if (this.showInputAmout === false) {
					this.line.amount = this.line.amountTotal - this.line.amountVat;
				} else {
					this.line.amountTotal = this.line.amount + this.line.amountVat;
				}

				this.originalAmount = this.line.amount;
			} else if (fieldName === 'amount') {
				if (this.line.amountVat === 0) {
					this.line.amountTotal = this.line.amount;
				} else {
					if (!_.isNil(this.line.vatId)) {
						this.reCalculateVatAmountAndTotalAmount(this.line.vatId);
					} else {
						this.line.amountTotal = this.line.amount + this.line.amountVat;
					}
				}
			}

			this.$emit('expense-splitting-item:updated', {
				index: this.index,
				item: {
					amount: parseFloat(parseFloat(this.line.amount).toFixed(2)),
					amountVat: parseFloat(parseFloat(this.line.amountVat).toFixed(2)),
					amountTotal: parseFloat(parseFloat(this.line.amountTotal).toFixed(2)),
					vatId: !_.isNil(this.line.vatId) ? +this.line.vatId : null
				},
				isLineValid: !this.$v.$invalid && !this.isInvalid
			});
		},
		removeItem() {
			this.$emit('expense-splitting-item:removed', {
				index: this.index
			});
		},
		reCalculateVatAmountAndAmount(id) {
			const vatValue = this.taxOptions.filter((tax) => !_.isNil(tax.item) && +id === +tax.item.id)[0].item.factor;

			let amount = this.line.amountTotal / ((vatValue + 100) / 100);
			amount = parseFloat(((amount * 100) / 100).toFixed(2));
			this.line.amount = amount;

			let amountVat = parseFloat((this.line.amountTotal - this.line.amount).toFixed(2));
			this.line.amountVat = amountVat;
		},
		editAmount() {
			this.showInputAmout = true;
			setTimeout(() => {
				this.$refs.amount.focus();
			}, 100);
		},
		handleOutside() {
			if (parseFloat(this.originalAmount) === parseFloat(this.line.amount)) {
				this.showInputAmout = false;
			}
		},
		reCalculateVatAmountAndTotalAmount(id) {
			const vatValue = this.taxOptions.filter((tax) => !_.isNil(tax.item) && +id === +tax.item.id)[0].item.factor;
			let amount = this.line.amount * ((vatValue + 100) / 100);
			amount = parseFloat(((amount * 100) / 100).toFixed(2));

			this.line.amountTotal = amount;

			let amountVat = parseFloat((this.line.amountTotal - this.line.amount).toFixed(2));
			this.line.amountVat = amountVat;
		}
	},
	watch: {
		nbRows: {
			handler(value) {
				if (!_.isNil(value)) {
					this.count = value;
				}
			},
			deep: true,
			immediate: true
		},
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.$v.$touch();
					this.line = value;
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		return {
			line: {
				amountTotal: {
					required,
					decimal,
					min: greaterThanZero
				},
				amount: {
					required,
					decimal,
					min: greaterThanZero
				}
			}
		};
	}
};
</script>
