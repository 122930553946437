<template>
	<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'" style="min-height: 100%; background: #f3f4f5; display: flex">
		<!-- <pre>{{ expense }}</pre> -->
		<!-- <pre>{{ item }}</pre> -->
		<!-- <pre>{{ items }}</pre> -->
		<!-- <pre>{{ items }}</pre> -->
		<b-row style="min-height: 100%; display: flex; flex: 1">
			<b-col class="" style="flex: 1" v-if="item">
				<validation-viewer
					ref="validation-viewer"
					:item="item"
					:index="index - 1"
					:totalItems="items.length"
					:disable-next="item ? index === items.length : true"
					:expense-id="+expense.id"
					:is-my-expense="isMyExpense"
					:can-make-validation="canMakeValidation"
					:can-only-make-split-item="canOnlyMakeSplitItem"
					:reload-data-after-img-inserted="reloadDataAfterImgInserted"
					:custom-expense-items="customExpenseItems"
					@expense-carousel-validation-viewer:reload="onCarouselValidationViewerRefreshed"
					@expense-carousel-validation-viewer:next="next"
					@expense-carousel-validation-viewer:back="back"
					@expense-carousel-validation-viewer:expenseItemRejected="onExpenseItemRejected"
					@expense-carousel-validation-viewer:reloadDataTable="onDataTableReloaded"
					@expense-carousel-validation-viewer:closeCarouselViewModal="onCarouselViewModalClosed"
				/>
			</b-col>
		</b-row>
		<reject-modal
			v-model="isRejectExpenseOpened"
			:expense-id="expense.id"
			@reject-expense-modal:close="onRejectExpenseModalClosed"
			@reject-expense-modal:rejected="onExpenseRejected"
		/>
	</div>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import ValidationViewer from '@/components/ExpenseService/CarouselView/ValidationViewer';
import { EXPENSE_NAME, EXPENSE_TYPE, FLAG_TYPE } from '@/shared/constants';
import _ from 'lodash';
import { mapActions } from 'vuex';
import { getProjectFlags } from '@/cruds/flag.crud';
import RejectModal from '@/components/ExpenseService/CarouselView/RejectExpenseModal';
import { classStatus } from '@/shared/global-status';

export default {
	name: 'ExpenseCarouselViewCard',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		ValidationViewer,
		RejectModal
	},
	props: {
		expense: {
			type: Object,
			required: false,
			default: null
		},
		expenseItem: {
			type: Object,
			required: false,
			default: null
		},
		expenseItems: {
			type: Array,
			required: true,
			default: () => []
		},
		canMakeValidation: {
			type: Boolean,
			required: false,
			default: false
		},
		reloadDataAfterImgInserted: {
			type: Boolean,
			required: false,
			default: true
		},
		customExpenseItems: {
			type: Object,
			required: false,
			default: null
		},
		canOnlyMakeSplitItem: {
			type: Boolean,
			required: false,
			default: false
		},
		isMyExpense: {
			type: Boolean,
			required: false,
			default: null
		}
	},
	data() {
		return {
			EXPENSE_NAME: EXPENSE_NAME,
			index: 1,
			item: null,
			id: 0,
			EXPENSE_TYPE: EXPENSE_TYPE,
			// flagItemId: 0
			flags: [],
			isRejectExpenseOpened: false,
			items: [],
			classStatus
		};
	},
	async created() {
		await getProjectFlags(FLAG_TYPE.DEFAULT_FLAG)
			.then((records) => {
				this.flags = records.customFlags;
			})
			.catch((error) => {
				console.log({ error });
			});
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		next() {
			this.index = this.index + 1;
			this.item = this.items[this.index - 1];
			this.$refs['validation-viewer'].listenVatChanged(this.getDefaultVatId());
			this.$refs['validation-viewer'].setDefaultCustomFlags(this.item);
		},
		back() {
			this.index = this.index - 1;
			this.item = this.items[this.index - 1];
			this.$refs['validation-viewer'].listenVatChanged(this.getDefaultVatId());
			this.$refs['validation-viewer'].setDefaultCustomFlags(this.item);
		},
		getDefaultVatId() {
			if (
				this.item &&
				!_.isUndefined(this.item.projectFlagItems) &&
				!_.isNil(this.item.projectFlagItems) &&
				this.item.projectFlagItems.taxeFlags.length > 0
			) {
				return this.item.projectFlagItems.taxeFlags[0].id;
			}

			return 0;
		},
		onCarouselValidationViewerRefreshed() {
			this.$emit('expense-carousel-view-card:reload', this.index - 1);
		},
		onNextItem() {
			this.$refs['chevronRightNext'].click();
		},
		async onExpenseItemRejected(payload) {
			if (!_.isNil(this.item)) {
				await this.getNotifications();

				this.item = {
					...this.item,
					comment: payload.comment,
					validated: payload.validated,
					statusClass: payload.statusClass,
					validatedStatus: payload.validatedStatus
				};

				this.$emit('expense-carousel-view-card:expenseItemRejected', {
					index: this.index - 1,
					data: this.item
				});
			}
		},
		onRejectExpenseModalClosed() {
			this.isRejectExpenseOpened = false;
		},
		async onExpenseRejected(payload) {
			await this.getNotifications();
			this.$emit('expense-carousel-view-card:redirectAndReload');
			this.$emit('expense-carousel-view-card:closeModal');
		},
		onCarouselViewModalClosed() {
			this.$emit('expense-carousel-view-card:closeModal');
		},
		onDataTableReloaded({ expenseId, newExpenseItems, index }) {
			this.index = index;
			this.item = newExpenseItems[0];
			this.$emit('expense-carousel-view-card:reload', index);
			this.$refs['validation-viewer'].listenVatChanged(this.getDefaultVatId());
			this.$refs['validation-viewer'].setDefaultCustomFlags(this.item);
		},
		saveExpenseItem() {
			this.$refs['validation-viewer'].saveExpenseItem();
		}
	},
	watch: {
		expenseItems: {
			handler(value) {
				if (!_.isNil(value)) {
					// this.$nextTick(() => {
					this.items = value;
					// });
				}
			},
			deep: true,
			immediate: true
		},
		expenseItem: {
			handler(value) {
				if (!_.isNil(value)) {
					this.index = this.expenseItem.index + 1;
					this.item = this.expenseItem.data;
					if (!_.isNil(this.$refs['validation-viewer']) && !_.isUndefined(this.$refs['validation-viewer'])) {
						this.$refs['validation-viewer'].listenVatChanged(this.getDefaultVatId());
						this.$refs['validation-viewer'].setDefaultCustomFlags(this.item);
					}
				}
			},
			deep: true,
			immediate: true
		},
		customExpenseItems: {
			handler(value) {
				if (!_.isNil(value)) {
					// console.log({ value });
					this.index = +value.index;
					const item = value.data[+value.index - 1];
					const items = value.data;
					this.item = {
						...item,
						statusClass: classStatus(item.validated)
					};
					this.items = [...items];
					if (!_.isNil(this.$refs['validation-viewer']) && !_.isUndefined(this.$refs['validation-viewer'])) {
						this.$refs['validation-viewer'].listenVatChanged(this.getDefaultVatId());
						this.$refs['validation-viewer'].setDefaultCustomFlags(this.item);
					}
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss" scoped></style>
