<template>
	<div class="animated fadeIn form" style="display: flex; position: relative; max-height: calc(100vh - 180px); min-height: 96%">
		<b-row style="min-height: 95%; flex: 1" v-if="expenseItem">
			<!-- <pre>{{ expenseItem }}</pre> -->
			<!-- <pre>{{ EXPENSE_TYPE }}</pre> -->
			<!-- <pre>{{ index }}</pre> -->
			<!-- <pre>{{ canEditExpense }}</pre> -->
			<b-col sm="5" class="carousel-wrapper">
				<b-row v-if="expenseItem.type < EXPENSE_TYPE.TRAVEL">
					<b-col style="height: 100%">
						<carousel
							v-if="expenseItem.images.length > 0"
							:value="[...expenseItem.images].reverse()"
							:num-visible="numberItemVisible"
							:num-scroll="numberScroll"
							:key="carouselImgUpd"
							orientation="horizontal"
							:verticalViewPortHeight="`100%`"
							contentClass="main-carousel"
							style="height: 100%; justify-content: space-between"
						>
							<template #item="slotProps">
								<div class="product-item">
									<div class="product-item-content">
										<div class="parent cursor-pointer">
											<div class="slide" v-if="getFileExtension(slotProps.data) !== 'pdf'">
												<b-img-lazy
													:src="getImage(slotProps.data)"
													:alt="slotProps.data"
													fluid-grow
													style="border-radius: 8px; height: 100%"
													class="px-1"
												/>
											</div>
											<div class="iframe-pdf" v-else>
												<iframe class="full full_h clearfix" :src="getImage(slotProps.data)" frameborder="0" />
											</div>
										</div>
									</div>
								</div>
								<div class="child">
									<div class="actions">
										<b-button
											type="button"
											variant="transparent"
											class=""
											@click="showPicture(slotProps)"
											v-b-tooltip.hover.top="{ variant: 'primary' }"
											:title="FormMSG(61, 'Preview')"
										>
											<component :is="getLucideIcon('Eye')" :size="32" :stroke-width="2" style="margin-top: 4px" color="#3f9b9b" />
										</b-button>
										<b-button
											type="button"
											variant="transparent"
											v-b-tooltip.hover.top="{ variant: 'danger' }"
											:title="FormMSG(60, 'Delete')"
											class=""
											@click="removePicture(slotProps)"
											v-if="canEditExpense"
										>
											<component :is="getLucideIcon('Trash2')" :size="32" :stroke-width="2" color="#ea4e2c" />
										</b-button>
										<b-button
											variant="transparent"
											class="d-flex justify-content-center align-items-center"
											@click="onImageAdded"
											:disabled="loadingImage"
											v-b-tooltip.hover.top="{ variant: 'primary' }"
											:title="FormMSG(45, 'Add images')"
											:style="`${expenseItem.images.length > 1 ? 'margin: 12px 0 8px 0' : ''}`"
											v-if="canEditExpense"
										>
											<b-spinner v-show="loadingImage" small style="margin-bottom: 1px" />
											<component v-if="!loadingImage" :is="getLucideIcon('FilePlus')" :size="32" :stroke-width="2" color="#3f9b9b" />
											<!-- <div class="px-2" style="margin-bottom: -2px">
												{{ FormMSG(45, 'Add images') }}
											</div> -->
										</b-button>
									</div>
								</div>
							</template>
						</carousel>
						<div v-else style="display: flex; height: 100%; width: 100%; align-items: center; justify-content: center">
							<b-button
								size="sm"
								variant="outline-primary"
								class="w-138-px d-flex justify-content-center align-items-center"
								@click="onImageAdded"
								:disabled="loadingImage"
								:style="`${expenseItem.images.length > 1 ? 'margin: 12px 0 8px 0' : ''}`"
							>
								<b-spinner v-show="loadingImage" small style="margin-bottom: 1px" />
								<component v-if="!loadingImage" :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
								<div class="px-2" style="margin-bottom: -2px">
									{{ FormMSG(45, 'Add images') }}
								</div>
							</b-button>
						</div>
					</b-col>
				</b-row>
			</b-col>
			<b-col sm="7" style="overflow: hidden auto; max-height: calc(100vh - 226px)">
				<!-- Expense Item selection -->
				<div class="d-flex w-100 flex-column align-items-center justify-content-center m-2" style="width: fit-content">
					<div class="d-flex align-items-center justify-content-start py-2">
						<div v-if="totalItems > 1">
							<button
								ref="chevronRightBack"
								class="btn-transparent text-color-rhapsody-in-blue"
								:disabled="item ? index === 0 : true"
								@click="back"
							>
								<component :is="getLucideIcon('ChevronLeft')" color="rgba(6, 38, 62, 0.64)" />
							</button>
						</div>
						<div class="">
							<div v-if="expenseId" class="text-color-rhapsody-in-blue fs-16 fw-40 text-bold" style="color: rgba(6, 38, 62, 0.74)">
								{{
									FormMSG(58, 'Expense item') +
									'&nbsp;(#' +
									(item ? item.id : '') +
									')' +
									'&nbsp;&nbsp;' +
									(item ? index + 1 : 1) +
									' / ' +
									totalItems
								}}
							</div>
						</div>
						<div v-if="totalItems > 1">
							<button
								ref="chevronRightNext"
								class="btn-transparent text-color-rhapsody-in-blue"
								:disabled="item ? index + 1 === totalItems : true"
								@click="next"
							>
								<component :is="getLucideIcon('ChevronRight')" color="rgba(6, 38, 62, 0.64)" />
							</button>
						</div>
					</div>
					<div class="wrap-status justify-content-center d-flex">
						<div :class="`status ${item ? item.statusClass : ''}`" style="font-size: 0.95rem; letter-spacing: 0.1rem">
							{{ item ? item.validatedStatus : '' }}
						</div>
					</div>
				</div>
				<!--/Expense Item selection -->
				<b-row v-if="expenseItem">
					<b-col sm="3">
						<b-form-group :label="FormMSG(1, 'Date')">
							<v-date-picker
								v-model="expenseItem.date"
								:available-dates="{
									start: null,
									end: null
								}"
								:locale="lang"
								:masks="masks"
								@dayclick="handleDate"
							>
								<template v-slot="{ inputValue, togglePopover }">
									<b-input-group>
										<b-form-input :value="inputValue" class="br-none" readonly :disabled="!canEditExpense" />
										<b-input-group-append>
											<b-input-group-text
												class="bg-color-white input-group-text-bg-white"
												:class="{ 'cursor-pointer': canEditExpense }"
												@click="canEditExpense ? togglePopover() : ''"
												:disabled="!canEditExpense"
											>
												<component :is="getLucideIcon('Calendar')" color="rgba(6, 38, 62, .6)" :size="18" :stroke-width="2.25" />
											</b-input-group-text>
										</b-input-group-append>
									</b-input-group>
								</template>
							</v-date-picker>
						</b-form-group>
					</b-col>
					<b-col sm="6">
						<department-selector
							:edit-data="expenseItem"
							:is-submitted="isSubmitted"
							:is-new="isNew"
							:disabled="!canEditExpense"
							department-desktop-size="6"
							category-desktop-size="6"
							hide-transportation-mean
							@change="handleDepartmentAndCatChange"
							@department:selector:invalid="isInvalidDeptCatFields"
						/>
					</b-col>
					<b-col sm="3">
						<b-form-group>
							<label>CO<sub>2</sub></label>
							<b-input-group>
								<b-form-input :value="rendKgCo2(expenseItem.kgCoTwo)" disabled />
								<b-input-group-append>
									<b-input-group-text>
										<component :is="getLucideIcon('Sprout')" color="#00A09C" :size="20" :stroke-width="2.25" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type === EXPENSE_TYPE.TRAVEL">
					<b-col sm="12">
						<transport-type-selector
							ref="transportTypeRef"
							:data-additional-detail="isNil(expenseItem.additionalSubType) ? 0 : expenseItem.additionalSubType"
							:data-detail="isNil(expenseItem.subType) ? 0 : expenseItem.subType"
							:data-type="isNil(expenseItem.subCategory) ? 0 : expenseItem.subCategory"
							:disable-form="!canEditExpense"
							hide-boat-type
							hide-freight-type
							hide-menues-not-two-wheels
							@transportation-type-selector:change="handleTransportationTypeSelectorChange"
							@transportation-type-selector:invalid="onTransportationTypeSelectorValidate"
						/>
					</b-col>
				</b-row>
				<b-row>
					<b-col :sm="`${String(expenseItem.type < EXPENSE_TYPE.TRAVEL ? 6 : 12)}`">
						<b-form-group :label="FormMSG(8, 'Description')">
							<b-form-textarea
								v-model="expenseItem.description"
								:disabled="!canEditExpense"
								style="height: 7rem"
								:class="{
									'is-invalid': $v.expenseItem.description.$error
								}"
							/>
							<div v-if="$v.expenseItem.description.$error" class="invalid-feedback">
								{{ FormMSG(50, 'Please, reference is required') }}
							</div>
						</b-form-group>
					</b-col>
					<b-col sm="6" v-if="expenseItem.type < EXPENSE_TYPE.TRAVEL">
						<b-row>
							<b-col>
								<supplier-selector
									:title="FormMSG(9, 'Supplier')"
									:label="FormMSG(10, 'Supplier')"
									:placeholder="FormMSG(11, 'Type to search ...')"
									:supplier-id="expenseItem.supplierId"
									:show-map="false"
									@supplier:unselected="onUnselected"
									@change="handleSupplierChanged"
									:ocr-suppliers="ocrImages"
									:disabled="!canEditExpense"
									:hide-action="!canEditExpense"
									use-new-design
									version="1.0"
								/>
							</b-col>
						</b-row>
						<b-row>
							<b-col class="mt-3">
								<b-form-group :label="FormMSG(12, 'Payment method')">
									<b-form-select v-model="expenseItem.paiementType" :options="paymentTypeOptions" :disabled="!canEditExpense" />
								</b-form-group>
							</b-col>
						</b-row>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type < EXPENSE_TYPE.TRAVEL">
					<b-col sm="3">
						<b-form-group :label="FormMSG(16, 'Total amount (tax incl.)')">
							<b-input-group>
								<b-form-input
									:class="
										isInvalidAmount || ($v.expenseItem && $v.expenseItem.amountTotal && $v.expenseItem.amountTotal.$error)
											? 'is-invalid'
											: ''
									"
									v-model="expenseItem.amountTotal"
									type="number"
									step="0.01"
									min="0"
									placeholder="0"
									:disabled="!canEditExpense"
									@input="handleAmountTotalChange"
								/>
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
								<small
									v-if="isInvalidAmount || ($v.expenseItem && $v.expenseItem.amountTotal && $v.expenseItem.amountTotal.$error)"
									style="color: red"
								>
									{{
										isInvalidAmount
											? FormMSG(51, 'Must be greater than VAT amount')
											: $v.expenseItem && $v.expenseItem.amountTotal.$error
											? FormMSG(52, 'Please, value must be greater than 0')
											: ''
									}}
								</small>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group :label="FormMSG(14, 'VAT Code')">
							<v-select
								ref="flag-item"
								v-model="vatType"
								label="text"
								:options="taxOptions"
								:reduce="(option) => option.value"
								:get-option-label="(option) => (option.item ? option.item.factor : '')"
								placeholder="..."
								:selectable="selectableItem"
								:disabled="!canEditExpense"
								@input="handleVatChange"
							>
								<template #option="option">
									<div class="text-bold" v-if="option.disabled">{{ option.text }}</div>
									<div class="text-color ml-3" v-else>{{ option.text }} - {{ option.item.name }} ({{ option.item.factor }}%)</div>
								</template>
							</v-select>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group :label="FormMSG(15, 'VAT amount')">
							<b-input-group>
								<b-form-input
									:class="isInvalidAmount ? 'is-invalid' : ''"
									v-model="expenseItem.amountVat"
									:max="expenseItem.amountTotal"
									type="number"
									step="0.01"
									min="0"
									placeholder="0"
									:disabled="!canEditExpense || vatAmountDisabled"
								/>
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
							<small v-if="isInvalidAmount" style="color: red">{{ FormMSG(53, 'Must be smaller than total amount') }}</small>
						</b-form-group>
					</b-col>
					<b-col sm="3">
						<b-form-group :label="FormMSG(13, 'Amount (tax excl.)')">
							<b-input-group>
								<b-form-input v-model="expenseItem.amount" type="number" step="0.01" min="0" placeholder="0" disabled />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type === EXPENSE_TYPE.TRAVEL">
					<b-col sm="12">
						<google-distance
							:edit-data="expenseItem"
							:to-location-label="FormMSG(18, 'To')"
							:from-location-label="FormMSG(17, 'From')"
							:to-location-error-message="FormMSG(29, 'Arrival point, is required')"
							:from-location-error-message="FormMSG(30, 'Starting point, is required')"
							:inline-validator="true"
							:is-submitted="isSubmitted"
							:disabled="!canEditExpense"
							@google-distance:selector-from:invalid="isInvalidGoogleFrom"
							@google-distance:selector-to:invalid="isInvalidGoogleTo"
							@google-distance:loading="calculateLoading"
							@change="handleDistanceChanges"
							:distance-valid="expenseItem.km > 0"
						/>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type === EXPENSE_TYPE.TRAVEL">
					<b-col sm="4">
						<b-form-group :label="FormMSG(19, 'Distance')">
							<b-input-group>
								<b-form-input
									v-model="expenseItem.km"
									id="expense-travel-distance"
									type="number"
									placeholder="0"
									min="0"
									step="0.001"
									:readonly="loading"
									:disabled="!canEditExpense"
									:class="{
										'is-invalid': $v.expenseItem.km.$error
									}"
								/>
								<b-input-group-append>
									<b-input-group-text>
										<span class="font-weight-bold" style="color: #06263e">{{ distanceUnit }}</span>
									</b-input-group-text>
								</b-input-group-append>
								<div v-if="$v.expenseItem.km.$error" class="invalid-feedback">
									{{ FormMSG(62, 'Please, value must be greater than 0') }}
								</div>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group :label="`${FormMSG(20, 'Rate') + ' (' + distanceUnit + ')'}`">
							<b-input-group>
								<b-form-input :value="expenseItem.kmRate" type="number" step="0.01" min="0" placeholder="0" readonly />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
					<b-col sm="4">
						<b-form-group :label="FormMSG(21, 'Amount')">
							<b-input-group>
								<b-form-input v-model="expenseItem.amountTotal" type="number" step="0.01" min="0" placeholder="0" readonly />
								<b-input-group-append>
									<b-input-group-text>
										<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
									</b-input-group-text>
								</b-input-group-append>
							</b-input-group>
						</b-form-group>
					</b-col>
				</b-row>
				<b-row v-if="flags.length > 0">
					<b-col>
						<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
								{{ FormMSG(22, 'Flags') }}
							</legend>
							<b-row class="mt-2 px-0">
								<b-col :sm="sizeFlagInput(flags.length)" class="d-flex mb-2 w-100" v-for="(flag, index) in flags" :key="index">
									<b-form-group :label="`${flag.name.toUpperCase()}`" class="w-100">
										<v-select
											v-model="flag.customFlagSelected"
											label="name"
											placeholder="..."
											:options="flag.projectFlagItem"
											:reduce="(option) => option.id"
											:disabled="!canEditExpense"
											@input="customFlagChanged($event, flag.flagId)"
										/>
									</b-form-group>
								</b-col>
							</b-row>
						</fieldset>
					</b-col>
				</b-row>
				<b-row v-if="expenseItem.type < EXPENSE_TYPE.TRAVEL">
					<b-col>
						<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
								{{ FormMSG(23, 'Green detail') }}
							</legend>
							<b-row>
								<b-col sm="12" class="pt-2 pb-3 pl-2">
									<co-two-clap
										ref="coTwoClapTicket"
										:parent-id="+expenseItem.id"
										:entity-type="1"
										:for-create="true"
										:external-carbon-data="carbonData"
										:disable-all="!canEditExpense"
										hide-location-menu
										hide-premise-menu
										hide-btn-reviewed
										@co-two-clap:change="handleCoTwoClapChange"
									/>
								</b-col>
							</b-row>
						</fieldset>
					</b-col>
				</b-row>
				<b-row v-else>
					<b-col>
						<fieldset :class="`${$screen.width <= 576 ? 'card-inside my-0 py-0 pb-0' : 'scheduler-border'}`">
							<legend :class="`${$screen.width <= 576 ? 'card-inside' : 'scheduler-border'}`" class="text-color-rhapsody-in-blue-2">
								{{ FormMSG(23, 'Green detail') }}
							</legend>
							<b-row>
								<b-col sm="12" class="pt-2 pb-3 pl-2">
									<co-two-clap
										ref="coTwoClapTicket"
										:parent-id="+expenseItem.id"
										:entity-type="1"
										:for-create="false"
										:external-carbon-data="carbonData"
										:disable-all="canEditExpense"
										hide-location-menu
										hide-premise-menu
										hide-btn-reviewed
										disable-carbon-type-selector
										hide-distance-known-transport
										hide-transport-type-selector-transport
										hide-from-address-transport
										hide-to-address-transport
										hide-distance-transport
										get-my-vehicule-in-transport
										@co-two-clap:change="handleCoTwoClapChange"
									/>
								</b-col>
							</b-row>
						</fieldset>
					</b-col>
				</b-row>
			</b-col>
			<capture-images
				ref="capture-images"
				multiple
				preview-mode="button"
				parent-type="expense_item"
				parent-sub-type="ticket"
				:parent-id="expenseItem.id"
				:pictures="rendImagesListForPreviewer"
				:preview-modal-options="{ zIndex: 1041, hideLikeDislike: true, hideCommentButton: true, hideDisLikeButton: true }"
				:dispatch-event-add-action="true"
				@change="handleImgsTaken"
				@upload-image:loading="handleUploadImgStatus"
			/>
			<reject-expense-item-modal
				ref="reject-item-modal"
				v-model="isRejectExpenseOpened"
				:id="expenseItem.id"
				:type-name="EXPENSE_TREE_NAME.CHILDREN"
				@reject-expense-item-modal:close="onRejectExpenseModalClosed"
				@reject-expense-item-modal:rejected="onExpenseItemRejected"
				@reject-expense-item-modal:redirectAndReloadExpenseDatatable="onExpenseDatatableRedirectAndReloaded"
			/>
			<!-- <Capture v-if="!$isPwa()" v-model="isCaptureModalOpen" multiple-img dynamic-crop-size :edit-data="editData" @change="handleImgsTaked" /> -->
			<expense-splitting-modal
				v-model="isExpenseSplittingOpen"
				:expense-id="+expenseId"
				:expense-item="expenseItem"
				:index="index"
				:selected-custom-flags="newValueCustomFlags"
				@expense-splitting-modal:closed="onExpenseSplittingClosed"
				@expense-splitting-modal:reload="onExpenseSplittingReloaded"
			/>
		</b-row>
		<!-- Footer button of expense item -->
		<b-row v-if="expenseId && expenseItem" class="w-100 p-2 w-auto footer-expense-item" style="padding: 12px 2px 8px 0 !important">
			<b-col class="d-flex justify-content-end align-items-center" style="border-top: 0.005rem dotted rgba(226, 230, 235, 0.85)">
				<b-button
					size="sm"
					variant="custom-outline-blue"
					class="w-138-px d-flex justify-content-center align-items-center mr-3"
					style="margin-top: -1px"
					@click="splitExpenseItem"
					v-if="
						canEditExpense &&
						(canMakeValidation ||
							(canOnlyMakeSplitItem &&
								(expenseItem.validated === VALIDATION_TYPE.VALIDATED_LEVEL_1 ||
									expenseItem.validated === VALIDATION_TYPE.VALIDATED_LEVEL_2))) &&
						expenseItem.type < EXPENSE_TYPE.TRAVEL
					"
					block
				>
					<component :is="getLucideIcon(ICONS.PLUS_CIRCLE.name)" :size="18" class="mr-2" />
					<div style="margin-top: 2px">
						{{ FormMSG(57, 'Split Item') }}
					</div>
				</b-button>
				<b-button
					size="sm"
					variant="custom-outline-primary"
					class="w-138-px d-flex justify-content-center align-items-center mr-3"
					@click="undoRejectExpenseItem"
					block
					v-if="
						(expenseItem.validated === VALIDATION_TYPE.REFUSED_LEVEL_1 || expenseItem.validated === VALIDATION_TYPE.REFUSED_LEVEL_2) &&
						canMakeValidation
					"
				>
					<component :is="getLucideIcon(ICONS.UNDO_2.name)" :size="18" class="mr-2" />
					<div style="margin-top: 2px">
						{{ FormMSG(47, 'Undo Rejection') }}
					</div>
				</b-button>
				<b-button
					size="sm"
					variant="outline-danger"
					class="w-138-px d-flex justify-content-center align-items-center"
					@click="rejectExpenseItem"
					style="margin-top: -2px"
					block
					v-else-if="
						expenseItem.validated !== VALIDATION_TYPE.REFUSED_LEVEL_1 &&
						expenseItem.validated !== VALIDATION_TYPE.REFUSED_LEVEL_2 &&
						canEditExpense &&
						canMakeValidation
					"
				>
					<component :is="getLucideIcon(ICONS.X.name)" :size="18" class="mr-2" />
					<div style="margin-top: 2px">
						{{ FormMSG(24, 'Reject') }}
					</div>
				</b-button>
				<!-- <pre>{{ canEditExpense }} - {{ isMyExpense }}</pre> -->
				<b-button
					size="sm"
					variant="custom-primary-blue"
					class="mx-3 w-138-px d-flex justify-content-center align-items-center"
					style="margin-top: -1px"
					@click="saveExpenseItem"
					:disabled="disableSaveBtn"
					block
					v-if="canEditExpense"
				>
					<component :is="getLucideIcon('Save')" :size="18" class="mr-2" />
					<div style="margin-top: 2px">
						{{ FormMSG(46, 'Save') }}
					</div>
				</b-button>
				<b-button
					size="sm"
					variant="custom-outline-primary"
					class="w-138-px d-flex justify-content-center align-items-center"
					style="margin-top: -1px"
					@click="next"
					:disabled="disableNext"
					block
				>
					<component :is="getLucideIcon('ChevronRight')" :size="18" class="mr-2" />
					<div style="margin-top: 2px">{{ FormMSG(56, 'Go next') }}</div>
				</b-button>
			</b-col>
		</b-row>
	</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { isNil } from '@/shared/utils';
import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import SupplierSelector from '@/components/SupplierSelector';
import CurrencySvg from '@/components/Svg/Currency';
import Carousel from 'primevue/carousel';
import {
	EXPENSE_TYPE,
	SUPPLIER,
	FLAG_TYPE,
	ERROR_STRING,
	FLAG_ENTITY_ITEM_TYPE,
	VALIDATION_TYPE,
	INIT_DATA_CARBON,
	EXPENSE_TREE_NAME
} from '@/shared/constants';
import _ from 'lodash';
import { store } from '@/store';
import { getProjectFlags, addUpdateProjectFlagEntity } from '@/cruds/flag.crud';
import GoogleDistance from '@/components/GoogleDistance';
import CoTwoClap from '@/modules/carbonclap/components/green/CoTwoClap';
import { getFileExtension, rendKgCo2 } from '@/shared/helpers';
import CaptureImages from '@/components/Packages/Captures/components/Main';
import RejectExpenseItemModal from '@/components/ExpenseService/CarouselView/RejectExpenseItemModal';
// import Capture from '@/components/ExpenseService/Capture';
import { undoRejectExpenseItem } from '@/cruds/expense.crud';
import Loading from 'vue-loading-overlay';
import { validationMixin } from 'vuelidate';
import { required, decimal, minLength } from 'vuelidate/lib/validators';
import { objReducer, greaterThanZero, generateSecureId } from '@/shared/utils';
// import moment from 'moment';
import TransportTypeSelector from '@/modules/transports/components/TransportTypeSelector';
import { getVehicule } from '@/cruds/vehicules.crud';
import { recalculateDistanceValueByUnit, recalculateDistanceValueToKm } from '@/shared/google/helpers';
import { GetKmGreenImpact } from '@/cruds/carbon.crud';
import ExpenseSplittingModal from '@/components/ExpenseService/ExpenseSplittingModal';
import { noTilde } from '~utils';

export default {
	name: 'ExpenseCarouselValidationViewer',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	components: {
		'v-date-picker': DatePicker,
		Treeselect,
		SupplierSelector,
		CurrencySvg,
		Carousel,
		DepartmentSelector: () => ({
			component: import('@/components/DepartmentsSelection')
		}),
		GoogleDistance,
		CoTwoClap,
		CaptureImages,
		RejectExpenseItemModal,
		Loading,
		TransportTypeSelector,
		ExpenseSplittingModal
	},
	computed: {
		...mapGetters({
			taxOptions: 'flag/taxOptions'
		}),
		flagFields() {
			return [
				{
					key: 'short',
					label: this.FormMSG(26, 'Flag'),
					class: 'text-center',
					sortable: true,
					thStyle: { width: '15%' }
				},
				{
					key: 'description',
					label: this.FormMSG(27, 'Description'),
					sortable: false,
					thStyle: { width: '55%' }
				},
				{
					key: 'selected',
					label: this.FormMSG(28, 'Selection'),
					class: 'text-center',
					sortable: false,
					thStyle: { width: '30%' }
				}
			];
		},
		distanceUnit() {
			return store.state.myProfile.distanceUnit;
		},
		paymentTypeOptions() {
			let menus = this.FormMenu(1005);

			menus.sort((a, b) => {
				if (noTilde(a.text).toLowerCase() < noTilde(b.text).toLowerCase()) {
					return -1;
				}
				if (noTilde(a.text).toLowerCase() > noTilde(b.text).toLowerCase()) {
					return 1;
				}

				return 0;
			});

			return menus;
		},
		rendImagesListForPreviewer() {
			if (this.expenseItem.images.length > 0) {
				return this.expenseItem.images.map((img) => {
					return {
						xid: img,
						src: `${process.env.VUE_APP_GQL}/images/${img}`,
						thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
						ext: getFileExtension(img)
					};
				});
			}

			return [];
		},
		currentContract() {
			return this.getCurrentContract();
		},
		canEditExpense() {
			if (!_.isNil(this.isMyExpense) && this.isMyExpense === true) return !this.isMyExpense;
			return !!store.canEditExpense();
		},
		isProd() {
			return store.isProd();
		},
		isInvalidAmount() {
			if (parseFloat(this.expenseItem.amountTotal) > 0) {
				return parseFloat(this.expenseItem.amountTotal) < parseFloat(this.expenseItem.amountVat);
			}
		}
	},
	props: {
		item: {
			type: Object,
			required: true,
			default: null
		},
		totalItems: {
			type: Number,
			required: true,
			default: 1
		},
		disableNext: {
			type: Boolean,
			default: false
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		expenseId: {
			type: [Number, String],
			required: false,
			default: 0
		},
		canMakeValidation: {
			type: Boolean,
			required: false,
			default: false
		},
		isMyExpense: {
			type: Boolean,
			required: false,
			default: null
		},
		reloadDataAfterImgInserted: {
			type: Boolean,
			required: false,
			default: true
		},
		customExpenseItems: {
			type: Object,
			required: false,
			default: null
		},
		canOnlyMakeSplitItem: {
			type: Boolean,
			required: false,
			default: false
		}
	},
	data() {
		return {
			expenseItem: null,
			masks: {
				input: 'DD/MM/YYYY'
			},
			ocrImages: [],
			flags: [],
			typeco2: null,
			loading: false,
			images: [],
			EXPENSE_TYPE,
			isSubmitted: false,
			isNew: false,
			isInvalidDeptCat: false,
			SUPPLIER: SUPPLIER,
			loadingImage: false,
			isRejectExpenseOpened: false,
			defaultValueCustomFlags: [],
			newValueCustomFlags: [],
			numberItemVisible: 1,
			numberScroll: 1,
			carbonData: _.cloneDeep(INIT_DATA_CARBON),
			isSupplierUpdate: false,
			submitCarbon: false,
			vatType: null,
			originalVatAmount: 0,
			vatAmountDisabled: false,
			// vatValueChoosed: null,
			showAlertChangeTransportMean: false,
			isInvalidGoogleFields: false,
			isInvalid: false,
			// incRecalculate: 0,
			// dontRecalculate: false,
			flagEntityInputs: [],
			disableSaveBtn: false,
			VALIDATION_TYPE,
			isExpenseSplittingOpen: false,
			expenseSplittingItem: null,
			EXPENSE_TREE_NAME,
			carouselImgUpd: 0,
			isInvalidTransportation: false,
			invalidGoogleFrom: false,
			invalidGoogleTo: false,
			rendKgCo2,
			omittedFields: ['expenseId']
		};
	},
	mounted() {
		this.$nextTick(async () => {
			await this.getTaxOptions();
			this.initDefaultData();

			await getProjectFlags(FLAG_TYPE.DEFAULT_FLAG)
				.then((records) => {
					this.flags = records.customFlags;
					this.setDefaultCustomFlags();
				})
				.catch((error) => {
					console.log({ error });
				});

			if (this.expenseItem.type === EXPENSE_TYPE.TRAVEL) {
				this.$refs.transportTypeRef.isInvalid();
				this.expenseItem.additionalSubType = this.item.additionalSubType;
				this.expenseItem.subType = this.item.subType;
				this.expenseItem.subCategory = this.item.subCategory;
			}
			this.$v.$touch();
		});
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications',
			getTaxOptions: 'flag/getTaxOptions',
			setNewExpense: 'expenses/setNewExpense'
		}),
		isNil,
		handleDate(payload) {},
		onUnselected(payload) {
			this.expenseItem = {
				...this.expenseItem,
				supplierId: 0,
				supplierName: ''
			};
		},
		handleSupplierChanged(payload) {
			const { id, name } = payload;
			this.expenseItem = {
				...this.expenseItem,
				supplierId: +id,
				supplierName: name
			};
		},
		handleDepartmentAndCatChange({ category, department, subCategory }) {
			this.expenseItem = {
				...this.expenseItem,
				category,
				department,
				subCategory
			};
		},
		isInvalidDeptCatFields(payload) {
			this.isInvalidDeptCat = payload;
		},
		isInvalidGoogleFrom(payload) {
			this.invalidGoogleFrom = payload;
			this.isInvalidGoogleFields = this.invalidGoogleFrom || this.invalidGoogleTo || !this.expenseItem.km;
			this.isSubmitted = true;
		},
		isInvalidGoogleTo(payload) {
			this.invalidGoogleTo = payload;
			this.isInvalidGoogleFields = this.invalidGoogleFrom || this.invalidGoogleTo || !this.expenseItem.km;
			this.isSubmitted = true;
		},
		selectableItem(item) {
			return !item.disabled;
		},
		calculateLoading(payload) {
			this.loading = payload;
		},
		async handleDistanceChanges(payload) {
			this.expenseItem = {
				...this.expenseItem,
				...objReducer(['fromLocation', 'toLocation', 'km'], payload)
			};

			this.setOriginalKm();
			await this.updateKgCO();
		},
		getImage(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		showPicture(data) {
			const images = this.expenseItem.images.map((img) => {
				return {
					xid: img,
					src: `${process.env.VUE_APP_GQL}/images/${img}`,
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
					ext: getFileExtension(img)
				};
			});

			this.$previewImages({
				images,
				focusIndex: data.index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: false
				}
			});
		},
		removePicture(data) {
			const action = async () => {
				try {
					const reversedImgsList = [...this.expenseItem.images].reverse();
					const response = await this.$axios.$get(`/removeimage/${this.getFileName(reversedImgsList[data.index])}`);
					if (ERROR_STRING.ITEM_NOT_FOUND === response) {
						this.createToastForMobile(this.FormMSG(47, 'Error'), this.FormMSG(48, 'Image not found'), 'danger');
					} else {
						reversedImgsList.splice(data.index, 1);
						this.expenseItem.images = [...reversedImgsList].reverse();
						this.carouselImgUpd++;
					}

					if (this.reloadDataAfterImgInserted) {
						this.$emit('expense-carousel-validation-viewer:reload', this.index);
					}
				} catch (error) {
					this.createToastForMobile(this.FormMSG(47, 'Error'), this.FormMSG(70, 'Something went wrong'), 'danger');
				}
			};

			this.confirmModal(action, this.FormMSG(44, 'Are you sure ?'), 'danger');
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		/**
		 * @param {Array} images
		 */
		async handleImgsTaken(images) {
			// this.expenseItem.images = [...this.expenseItem.images, ...images];
			this.expenseItem.images = images;
			this.carouselImgUpd++;
			if (this.reloadDataAfterImgInserted) {
				this.$emit('expense-carousel-validation-viewer:reload', this.index);
			}
		},
		handleUploadImgStatus() {},
		onImageAdded() {
			this.$refs['capture-images'].$refs['add-images-none-pwa'].click();
		},
		async rejectExpenseItem() {
			const action = async () => {
				this.isRejectExpenseOpened = true;
			};
			this.confirmModal(action, this.FormMSG(49, 'Are you sure to reject this expense item ?'), 'danger');
		},
		async saveExpenseItem(e) {
			if (e) e.preventDefault();
			this.isSubmitted = true;

			this.$v.$touch();
			let isInvalid = await this.asyncIsinvalid();
			if (!isInvalid) {
				/**
				 * @TODO
				 * 1- Save expense
				 * 2- Save VAT Code
				 * 3- Save flags
				 * 4- Save Carbone
				 */
				try {
					await this.updateExpense();
					try {
						await this.updateExpenseFlagItems();
						this.createToastForMobile(this.FormMSG(54, 'Success'), this.FormMSG(55, 'Expense updated successfully.'), '');
						await new Promise((resolve) => {
							setTimeout(() => {
								resolve(this.$emit('expense-carousel-validation-viewer:reload', this.index));
							}, 1500);
						});
						this.flagEntityInputs = [];
					} catch (error) {
						console.error({ error });
					}
				} catch (error) {
					console.error({ error });
				}
			}
		},
		onRejectExpenseModalClosed() {
			this.isRejectExpenseOpened = false;
		},
		customFlagChanged(payload, flagId) {
			if (this.newValueCustomFlags.length === 0) {
				const items = this.flags.filter((flag) => +flag.flagId === +flagId)[0].projectFlagItem;
				const item = items.filter((item) => +item.id === +payload)[0];
				this.newValueCustomFlags.push({
					flagId,
					item
				});
			} else {
				const index = this.newValueCustomFlags.findIndex((flag) => flag.flagId === flagId);
				if (index === -1) {
					const items = this.flags.filter((flag) => +flag.flagId === +flagId)[0].projectFlagItem;
					const item = items.filter((item) => +item.id === +payload)[0];
					this.newValueCustomFlags.push({
						flagId,
						item
					});
				} else {
					if (_.isNil(payload)) {
						this.newValueCustomFlags.splice(index, 1);
					} else {
						const items = this.flags.filter((flag) => +flag.flagId === +flagId)[0].projectFlagItem;
						const item = items.filter((item) => +item.id === +payload)[0];
						this.newValueCustomFlags[index].item = item;
					}
				}
			}
			this.expenseItem.flagItemId = +payload;
		},
		async updateExpenseFlagItems() {
			for (const flag of this.newValueCustomFlags) {
				if (!_.isNil(flag.item)) {
					this.flagEntityInputs.push({
						flagId: +flag.item.flagId,
						value: +flag.item.value,
						entityId: +this.expenseItem.id,
						type: FLAG_ENTITY_ITEM_TYPE.expense,
						factor: +flag.item.factor
					});
				}
			}

			if (!_.isNil(this.vatType)) {
				const taxItem = this.taxOptions.filter((tax) => !_.isNil(tax.item) && +this.vatType === +tax.item.id)[0];

				this.flagEntityInputs.push({
					flagId: +taxItem.item.flagId,
					value: +taxItem.item.value,
					entityId: +this.expenseItem.id,
					type: FLAG_ENTITY_ITEM_TYPE.expense,
					factor: +taxItem.item.factor
				});
			}

			return await addUpdateProjectFlagEntity(+this.expenseItem.id, FLAG_ENTITY_ITEM_TYPE.expense, this.flagEntityInputs);
		},
		async onExpenseItemRejected(payload) {
			await this.getNotifications();
			this.$emit('expense-carousel-validation-viewer:expenseItemRejected', payload);
			this.$emit('expense-carousel-validation-viewer:reload', this.index);
			this.isRejectExpenseOpened = false;
		},
		async undoRejectExpenseItem() {
			await undoRejectExpenseItem(+this.expenseItem.id)
				.then(async (record) => {
					await this.getNotifications();
				})
				.catch((error) => {
					console.log(error);
				});
		},
		setDefaultCustomFlags(expense = null) {
			const expenseItem = !_.isNil(expense) ? expense : this.expenseItem;
			this.newValueCustomFlags = [];
			this.flags = this.flags.map((flag) => {
				const customFlag = expenseItem.projectFlagItems.customFlags.filter((item) => !_.isNil(item) && +flag.flagId === +item.flagId);
				if (customFlag.length > 0) {
					this.newValueCustomFlags.push({
						flagId: +customFlag[0].flagId,
						item: customFlag[0]
					});
				}

				return {
					...flag,
					customFlagSelected: customFlag.length > 0 ? customFlag[0].id : null
				};
			});
		},
		getFileExtension(fileName) {
			return getFileExtension(fileName);
		},
		initDefaultData(hideOriginal = false) {
			if (!hideOriginal) this.originalVatAmount = this.expenseItem.amountVat;
			this.expenseItem.flagItemId = 0;
			if (
				!_.isUndefined(this.expenseItem.projectFlagItems) &&
				!_.isNil(this.expenseItem.projectFlagItems) &&
				this.expenseItem.projectFlagItems.taxeFlags.length > 0
			) {
				this.vatType = this.expenseItem.flagItemId = this.expenseItem.projectFlagItems.taxeFlags[0].id;
				this.vatAmountDisabled = true;
			}

			if (!this.expenseItem.carbon) {
				this.expenseItem.carbon = {
					id: 0
				};
			}
			this.carbonData = {
				..._.omit(this.expenseItem.carbon, ['carbonValues', 'department', 'function', 'supplier', 'user']),
				carbonId: +this.expenseItem.carbon.id
			};
			// setTimeout(() => {
			// 	this.carbonData = {
			// 		..._.omit(this.expenseItem.carbon, ['carbonValues', 'department', 'function', 'supplier', 'user']),
			// 		carbonId: +this.expenseItem.carbon.id
			// 	};
			// }, 250);
		},
		handleVatChange(payload) {
			if (!_.isNil(payload)) {
				// console.log({ payload, taxOptions: this.taxOptions.filter((tax) => +payload === +tax.item.id && !tax.disabled)[0].item.factor });
				this.calculateVatAmount(payload);
				this.vatAmountDisabled = true;
				this.expenseItem.flagItemId = +payload;
			} else {
				this.expenseItem.amountVat = this.originalVatAmount;
				this.vatAmountDisabled = false;
				this.expenseItem.flagItemId = 0;
			}

			this.$v.$touch();
		},
		listenVatChanged(payload) {
			this.expenseItem.flagItemId = +payload;
			this.vatType = +payload > 0 ? payload : null;
			this.vatAmountDisabled = +payload > 0;
		},
		handleAmountTotalChange(payload) {
			if (!_.isNil(this.vatType)) {
				this.calculateVatAmount(this.vatType);
			}
		},
		handleTransportationTypeSelectorChange({ type, detail, additionalDetail }) {
			this.expenseItem.subCategory = type;
			this.expenseItem.subType = detail === null ? 0 : detail;
			this.expenseItem.additionalSubType = additionalDetail === null ? 0 : additionalDetail;
		},
		async handleCoTwoClapChange({ carbonData }) {
			if (this.expenseItem.type === EXPENSE_TYPE.TRAVEL) {
				this.carbonData.reason = carbonData.reason;
				this.carbonData.numberOfVehicule = carbonData.numberOfVehicule;
				this.carbonData.vehiculeId = carbonData.vehiculeId;
				this.carbonData.number = carbonData.number;

				if (carbonData.vehiculeId > 0) {
					const resultVehicule = await getVehicule(carbonData.vehiculeId);

					this.transportTypeFromVehicule = {
						subCategory: resultVehicule.type,
						subType: resultVehicule.subType,
						additionalSubType: resultVehicule.additionalSubType
					};

					this.showAlertChangeTransportMean = true;

					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			} else {
				this.carbonData = carbonData;
			}
		},
		generateTextId(texte) {
			return generateSecureId(texte);
		},
		calculateVatAmount(id) {
			const vatValue = this.taxOptions.filter((tax) => !_.isNil(tax.item) && +id === +tax.item.id)[0].item.factor;

			let amount = this.expenseItem.amountTotal / ((vatValue + 100) / 100);
			amount = parseFloat(((amount * 100) / 100).toFixed(2));
			this.expenseItem.amount = amount;

			let amountVat = parseFloat((this.expenseItem.amountTotal - amount).toFixed(2));
			this.expenseItem.amountVat = amountVat;
		},
		async asyncIsinvalid() {
			// console.log(this.$refs['coTwoClapTicket']);
			const tempCoTwoClap = this.$refs['coTwoClapTicket'];
			const result = await new Promise((resolve, reject) => {
				setTimeout(() => {
					const isInvalidCarbon = tempCoTwoClap.isInvalid();
					let isInvalid = this.$v.$invalid || isInvalidCarbon || this.isInvalidDeptCat;
					if (this.expenseItem.type === EXPENSE_TYPE.TRAVEL) {
						const validDist = this.isInvalidGoogleFields ? (this.expenseItem.km > 0 ? false : true) : this.isInvalidGoogleFields;
						isInvalid = isInvalid || this.isInvalidTransportation || validDist;
					}
					this.isInvalid = isInvalid;

					resolve(this.isInvalid);
					reject(false);
				}, 150);
			});
			return result;
		},
		async updateExpense() {
			this.expenseItem = _.omit(this.expenseItem, this.omittedFields);
			this.carbonData.carbonId = +this.expenseItem.carbon.id;

			if (this.expenseItem.type === EXPENSE_TYPE.TRAVEL) {
				this.expenseItem.km = parseFloat(this.expenseItem.km);
				this.carbonData.km = this.expenseItem.km;
			}
			let payload = {
				id: +this.expenseItem.id,
				expense: {
					...this.expenseItem,
					amount: parseFloat(this.expenseItem.amount),
					amountTotal: parseFloat(this.expenseItem.amountTotal),
					amountVat: parseFloat(this.expenseItem.amountVat),
					carbon: {
						...this.carbonData
					}
				},
				userId: +this.expenseItem.user.id
			};
			if (this.expenseItem.type < EXPENSE_TYPE.TRAVEL) {
				payload = {
					...payload,
					projectFlagItemId: this.expenseItem.flagItemId
				};
			}
			await this.setNewExpense(payload);
		},
		setOriginalKm() {
			if (
				!_.isNil(this.expenseItem.originalKm) &&
				this.expenseItem.originalKm > 0 &&
				this.expenseItem.originalKm !== this.expenseItem.km &&
				this.expenseItem.originalFromLocation === this.expenseItem.fromLocation &&
				this.expenseItem.originalToLocation === this.expenseItem.toLocation
			) {
				this.expenseItem.km = recalculateDistanceValueByUnit(this.expenseItem.originalKm);
			}
		},
		async updateKgCO() {
			if (recalculateDistanceValueToKm(this.expenseItem.km) > 0) {
				const transportMeanMapped = this.mappedTransportTypeToCarbonClapValue(
					this.expenseItem.subCategory,
					this.expenseItem.subType,
					this.expenseItem.additionalSubType
				);
				this.expenseItem.kgCoTwo = await GetKmGreenImpact(
					recalculateDistanceValueToKm(this.expenseItem.km),
					this.expenseItem.subCategory,
					this.expenseItem.subType
				);
			} else {
				this.expenseItem.kgCoTwo = 0;
			}
		},
		next() {
			this.$emit('expense-carousel-validation-viewer:next');
		},
		back() {
			this.$emit('expense-carousel-validation-viewer:back');
		},
		splitExpenseItem() {
			this.isExpenseSplittingOpen = true;
		},
		onExpenseSplittingClosed() {
			this.isExpenseSplittingOpen = false;
		},
		async onExpenseSplittingReloaded({ expenseId, newExpenseItems, index }) {
			this.$emit('expense-carousel-validation-viewer:reloadDataTable', { expenseId, newExpenseItems, index });
		},
		onExpenseDatatableRedirectAndReloaded() {
			this.isRejectExpenseOpened = false;
			setTimeout(() => {
				this.$emit('expense-carousel-validation-viewer:closeCarouselViewModal');
			}, 500);
			this.$router.go(-1);
		},
		sizeFlagInput(length) {
			let size = 4;

			if (length >= 4) {
				size = 3;
			}

			return size;
		},
		onTransportationTypeSelectorValidate(payload) {
			this.isInvalidTransportation = payload;
		}
	},
	watch: {
		item: {
			handler(value) {
				if (!_.isNil(value)) {
					this.$nextTick(() => {
						if (value.type === EXPENSE_TYPE.TRAVEL) {
							value = {
								...value,
								originalKm: value.km,
								originalFromLocation: value.fromLocation,
								originalToLocation: value.toLocation
							};
						}
						this.expenseItem = _.cloneDeep(value);
						this.carbonData = _.cloneDeep(value.carbon);
						this.carouselImgUpd++;
					});
				}
			},
			deep: true,
			immediate: true
		}
	},
	validations() {
		let patterns = null;

		if (this.expenseItem.type < EXPENSE_TYPE.TRAVEL) {
			if (parseFloat(this.expenseItem.amountTotal) < 0) {
				patterns = {
					expenseItem: {
						description: {
							required,
							min: minLength(4)
						}
					}
				};
			} else {
				patterns = {
					expenseItem: {
						amountTotal: {
							required,
							decimal,
							min: greaterThanZero
						},
						description: {
							required,
							min: minLength(4)
						}
					}
				};
			}
		} else {
			patterns = {
				expenseItem: {
					description: {
						required,
						min: minLength(4)
					},
					subCategory: {
						required
					},
					km: {
						required,
						decimal,
						min: greaterThanZero
					}
				}
			};
		}

		return patterns;
	}
};
</script>
<style lang="scss">
.text-bold {
	font-weight: 600;
	font-size: 1rem;
}

.text-color {
	font-size: 12px !important;
	color: rgba(6, 38, 62, 0.84) !important;
}

.footer-expense-item {
	position: fixed;
	bottom: 0;
	transform: translateY(-100%);
	right: 7px;
	background-color: #f3f4f5;
	border-top: 1px solid rgba(0, 0, 0, 0.15) !important;
	width: 100% !important;
}
</style>
