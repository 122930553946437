var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: _vm.$screen.width > 576 ? "container-layout" : "container-mobile",
      staticStyle: {
        "min-height": "100%",
        background: "#f3f4f5",
        display: "flex",
      },
    },
    [
      _c(
        "b-row",
        { staticStyle: { "min-height": "100%", display: "flex", flex: "1" } },
        [
          _vm.item
            ? _c(
                "b-col",
                { staticStyle: { flex: "1" } },
                [
                  _c("validation-viewer", {
                    ref: "validation-viewer",
                    attrs: {
                      item: _vm.item,
                      index: _vm.index - 1,
                      totalItems: _vm.items.length,
                      "disable-next": _vm.item
                        ? _vm.index === _vm.items.length
                        : true,
                      "expense-id": +_vm.expense.id,
                      "is-my-expense": _vm.isMyExpense,
                      "can-make-validation": _vm.canMakeValidation,
                      "can-only-make-split-item": _vm.canOnlyMakeSplitItem,
                      "reload-data-after-img-inserted":
                        _vm.reloadDataAfterImgInserted,
                      "custom-expense-items": _vm.customExpenseItems,
                    },
                    on: {
                      "expense-carousel-validation-viewer:reload":
                        _vm.onCarouselValidationViewerRefreshed,
                      "expense-carousel-validation-viewer:next": _vm.next,
                      "expense-carousel-validation-viewer:back": _vm.back,
                      "expense-carousel-validation-viewer:expenseItemRejected":
                        _vm.onExpenseItemRejected,
                      "expense-carousel-validation-viewer:reloadDataTable":
                        _vm.onDataTableReloaded,
                      "expense-carousel-validation-viewer:closeCarouselViewModal":
                        _vm.onCarouselViewModalClosed,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("reject-modal", {
        attrs: { "expense-id": _vm.expense.id },
        on: {
          "reject-expense-modal:close": _vm.onRejectExpenseModalClosed,
          "reject-expense-modal:rejected": _vm.onExpenseRejected,
        },
        model: {
          value: _vm.isRejectExpenseOpened,
          callback: function ($$v) {
            _vm.isRejectExpenseOpened = $$v
          },
          expression: "isRejectExpenseOpened",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }