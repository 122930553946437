var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.line
    ? _c(
        "b-tr",
        { staticClass: "animated fadeIn" },
        [
          _c("b-td", { staticStyle: { border: "none !important" } }, [
            _vm._v(" "),
          ]),
          _c(
            "b-td",
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        class: {
                          "is-invalid":
                            _vm.isInvalid || _vm.$v.line.amountTotal.$error,
                        },
                        attrs: { type: "number", step: "0.01", min: "0" },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("amountTotal")
                          },
                        },
                        model: {
                          value: _vm.line.amountTotal,
                          callback: function ($$v) {
                            _vm.$set(_vm.line, "amountTotal", $$v)
                          },
                          expression: "line.amountTotal",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c("currency-svg", {
                                attrs: {
                                  color: "#06263E",
                                  opacity: "0.85",
                                  width: "15",
                                  height: "15",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-td",
            { staticClass: "text-center" },
            [
              _c("v-select", {
                attrs: {
                  label: "text",
                  options: _vm.taxOptions,
                  reduce: (option) => option.value,
                  "get-option-label": (option) =>
                    option.item ? option.item.factor : "",
                  placeholder: "...",
                  selectable: _vm.selectableItem,
                },
                on: {
                  input: function ($event) {
                    return _vm.handleInputChanged("vatId")
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "option",
                      fn: function (option) {
                        return [
                          option.disabled
                            ? _c("div", { staticClass: "text-bold" }, [
                                _vm._v(_vm._s(option.text)),
                              ])
                            : _c("div", { staticClass: "text-color ml-3" }, [
                                _vm._v(
                                  "\n\t\t\t\t\t" + _vm._s(option.text) + " - "
                                ),
                                _c("b", [
                                  _vm._v(
                                    _vm._s(option.item.name) +
                                      " (" +
                                      _vm._s(option.item.factor) +
                                      "%)"
                                  ),
                                ]),
                              ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1987321604
                ),
                model: {
                  value: _vm.line.vatId,
                  callback: function ($$v) {
                    _vm.$set(_vm.line, "vatId", $$v)
                  },
                  expression: "line.vatId",
                },
              }),
            ],
            1
          ),
          _c(
            "b-td",
            [
              _c(
                "b-form-group",
                [
                  _c(
                    "b-input-group",
                    [
                      _c("b-form-input", {
                        class: _vm.isInvalid ? "is-invalid" : "",
                        attrs: {
                          type: "number",
                          step: "0.01",
                          min: "0",
                          disabled: _vm.vatAmountDisabled,
                        },
                        on: {
                          keyup: function ($event) {
                            return _vm.handleInputChanged("amountVat")
                          },
                        },
                        model: {
                          value: _vm.line.amountVat,
                          callback: function ($$v) {
                            _vm.$set(_vm.line, "amountVat", $$v)
                          },
                          expression: "line.amountVat",
                        },
                      }),
                      _c(
                        "b-input-group-append",
                        [
                          _c(
                            "b-input-group-text",
                            [
                              _c("currency-svg", {
                                attrs: {
                                  color: "#06263E",
                                  opacity: "0.85",
                                  width: "15",
                                  height: "15",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "b-td",
            [
              !_vm.showInputAmout
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.right",
                          modifiers: { hover: true, right: true },
                        },
                      ],
                      attrs: {
                        title: _vm.FormMSG(1, "Click to edit this amount"),
                      },
                      on: { click: _vm.editAmount },
                    },
                    [
                      _vm._v(
                        "\n\t\t\t" +
                          _vm._s(_vm.currencyFormat(_vm.line.amount)) +
                          "\n\t\t"
                      ),
                    ]
                  )
                : _c(
                    "b-form-group",
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            ref: "amount",
                            class: {
                              "is-invalid":
                                _vm.isInvalid || _vm.$v.line.amount.$error,
                            },
                            attrs: { type: "number", step: "0.01", min: "0" },
                            on: {
                              blur: _vm.handleOutside,
                              keyup: function ($event) {
                                return _vm.handleInputChanged("amount")
                              },
                            },
                            model: {
                              value: _vm.line.amount,
                              callback: function ($$v) {
                                _vm.$set(_vm.line, "amount", $$v)
                              },
                              expression: "line.amount",
                            },
                          }),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-input-group-text",
                                [
                                  _c("currency-svg", {
                                    attrs: {
                                      color: "#06263E",
                                      opacity: "0.85",
                                      width: "15",
                                      height: "15",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
          _c(
            "b-td",
            { staticClass: "text-center" },
            [
              _vm.index > 0
                ? _c(_vm.getLucideIcon(_vm.ICONS.TRASH.name), {
                    tag: "component",
                    staticClass: "cursor-pointer",
                    attrs: {
                      color: _vm.ICONS.TRASH.color,
                      "stroke-width": 2.5,
                      size: 18,
                    },
                    on: { click: _vm.removeItem },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }