<template>
	<b-modal
		id="expenseCarouselView"
		header-class="header-class-modal-doc-package"
		v-model="isOpen"
		size="xxl"
		centered
		cancel-variant="light"
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
		@hidden="emitEventClose"
		@show="prepareData"
	>
		<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'" style="height: 100%">
			<!-- <pre>{{ expenseId }}</pre> -->
			<!-- <pre>{{ expenseItem }}</pre> -->
			<!-- <pre>{{ index }}</pre> -->
			<!-- <pre>{{ selectedCustomFlags }}</pre> -->
			<b-row class="form" v-if="expenseItem" style="height: 100%">
				<b-col sm="5" class="carousel-wrapper">
					<carousel
						v-if="expenseItem.images.length > 0"
						:value="expenseItem.images"
						:num-visible="numberItemVisible"
						:num-scroll="numberScroll"
						orientation="horizontal"
						:verticalViewPortHeight="`${$screen.height - 350}px`"
						contentClass="main-carousel"
					>
						<template #item="slotProps">
							<div class="product-item">
								<div class="product-item-content">
									<div class="parent cursor-pointer">
										<div class="slide" v-if="getFileExtension(slotProps.data) !== 'pdf'">
											<b-img-lazy
												:src="getImage(slotProps.data)"
												:alt="slotProps.data"
												fluid-grow
												style="border-radius: 8px; height: 100%"
												class="px-1"
											/>
										</div>
										<div class="iframe-pdf" v-else>
											<iframe class="full full_h clearfix" :src="getImage(slotProps.data)" frameborder="0" />
										</div>
									</div>
								</div>
							</div>
							<div class="child">
								<div class="actions" style="margin-top: 32px">
									<b-button
										type="button"
										variant="transparent"
										class=""
										@click="showPicture(slotProps)"
										v-b-tooltip.hover.top="{ variant: 'primary' }"
										:title="FormMSG(61, 'Preview')"
									>
										<component :is="getLucideIcon('Eye')" :size="32" :stroke-width="2" style="margin-top: 4px" color="#3f9b9b" />
									</b-button>
								</div>
							</div>
						</template>
					</carousel>
				</b-col>
				<b-col sm="7" class="pr-0">
					<div class="sub-title pb-3" style="margin-left: 0">
						{{ FormMSG(5, 'Initial Expense item') }}
					</div>
					<b-row class="px-3 pb-2">
						<b-col sm="4">
							<b-form-group :label="FormMSG(8, 'Total amount (tax incl.)')">
								<b-input-group>
									<b-form-input :value="expenseItem.amountTotal" type="number" readonly />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group :label="FormMSG(7, 'Total VAT')">
								<b-input-group>
									<b-form-input :value="expenseItem.amountVat" type="number" readonly />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
						<b-col sm="4">
							<b-form-group :label="FormMSG(6, 'Amount (tax excl.)')">
								<b-input-group>
									<b-form-input :value="expenseItem.amount" type="number" readonly />
									<b-input-group-append>
										<b-input-group-text>
											<currency-svg color="#06263E" opacity="0.85" width="15" height="15" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-form-group>
						</b-col>
					</b-row>
					<div class="sub-title pb-3" style="margin-left: 0">
						{{ FormMSG(9, 'New splitting') }}
					</div>
					<b-row class="pb-2">
						<b-col>
							<div style="text-align: right; color: rgba(6, 38, 62, 0.64)">
								<span style="padding: 0 30px 0 0" class="fw-700">
									{{ FormMSG(11, 'Note: Initial expense item will be splitted into different lines') }}
								</span>
							</div>
							<div style="text-align: right">
								<span style="padding: 0 30px 0 0; color: red" class="fw-700">
									{{ FormMSG(49, 'NB: Click inside the box, if you want to edit "Amount (tax excl.)"') }}
								</span>
							</div>
							<b-row>
								<b-col class="px-5">
									<b-table-simple small id="expense-splitting">
										<b-thead head-variant="dark">
											<b-tr style="line-height: 24px" class="text-center">
												<b-th style="border: none !important; background-color: transparent !important; width: 6%">&nbsp;</b-th>
												<b-th style="width: 25%">{{ FormMSG(15, 'Total amount (tax incl.)') }}</b-th>
												<b-th style="width: 20.5%">{{ FormMSG(13, 'VAT Code') }}</b-th>
												<b-th style="width: 22%">{{ FormMSG(14, 'VAT Amount') }}</b-th>
												<b-th style="width: 21.5%">{{ FormMSG(12, 'Amount (tax excl.)') }}</b-th>
												<b-th style="width: 5%">&nbsp;</b-th>
											</b-tr>
										</b-thead>
										<b-tbody class="text-right">
											<item
												v-for="(item, i) in splittingItems"
												:key="i"
												:expense-id="+expenseId"
												:expense-item-id="+expenseItem.id"
												:index="i"
												:item="item"
												:nbRows="splittingItems.length"
												@expense-splitting-item:removed="onLineSplittingRemoved"
												@expense-splitting-item:updated="onLineSplittingChanged"
											/>
										</b-tbody>
										<b-tfoot class="text-right">
											<b-tr style="font-size: 1.1rem">
												<b-th
													class="text-left"
													style="
														border-top: 0.005rem solid rgba(226, 230, 235, 0.85);
														border-left: 0.005rem solid rgba(226, 230, 235, 0.85);
													"
												>
													<span class="px-1"> {{ FormMSG(16, 'TOTAL') }}</span>
												</b-th>
												<b-th :style="`color: ${globalValidation.isValidAmountTotal ? '#1f2021' : '#EA4E2C'}`">
													{{ currencyFormat(global.amountTotal) }}
												</b-th>
												<b-th colspan="2" :style="`color: ${globalValidation.isValidAmountVat ? '#1f2021' : '#EA4E2C'}`">
													{{ currencyFormat(global.amountVat) }}
												</b-th>
												<b-th :style="`color: ${globalValidation.isValidAmount ? '#1f2021' : '#EA4E2C'}`">
													{{ currencyFormat(global.amount) }}
												</b-th>
												<b-th style="border-right: none; border-bottom: none">&nbsp;</b-th>
											</b-tr>
										</b-tfoot>
									</b-table-simple>
								</b-col>
							</b-row>
						</b-col>
					</b-row>
					<b-row class="px-5">
						<div class="d-flex justify-content-end w-100">
							<b-button
								size="sm"
								variant="outline-primary"
								class="w-138-px d-flex justify-content-center align-items-center"
								@click="addSplittingItem"
							>
								<component :is="getLucideIcon('PlusCircle')" :size="16" :stroke-width="2" />
								<div class="px-2" style="margin-bottom: -2px">
									{{ FormMSG(10, 'Add Splitting') }}
								</div>
							</b-button>
						</div>
					</b-row>
				</b-col>
				<b-modal
					id="expense-splitting-warning-modal"
					header-class="header-class-modal-doc-package"
					:title="FormMSG(17, 'Warning')"
					class="modal-success"
					v-model="warningModal"
					@ok="warningModal = false"
					header-bg-variant="warning"
					header-border-variant="warning"
					ok-variant="light"
					ok-only
					centered
				>
					{{ FormMSG(18, 'Please fill (or correct the value of) the field(s) required (coloring red) or remove one (or all) of them.') }}
				</b-modal>
				<b-modal
					id="expense-splitting-error-modal"
					header-class="header-class-modal-doc-package"
					:title="FormMSG(19, 'Error')"
					class="modal-success"
					v-model="errorModal"
					@ok="errorModal = false"
					header-bg-variant="danger"
					header-border-variant="danger"
					ok-variant="light"
					ok-only
					centered
				>
					{{ FormMSG(20, 'Please verify, some of amounts are not correct.') }}
				</b-modal>
			</b-row>
		</div>
		<template #modal-title>
			<b-row>
				<b-col cols="4">
					<div style="font-size: 1.25rem">{{ FormMSG(3, 'Expense TVA Splitting') }}</div>
				</b-col>
				<b-col cols="4" class="text-center">
					<div style="font-size: 1.25rem">
						<!-- <span class="text-uppercase">{{ 'TICKET #3606' }}</span> -->
						<span class="text-uppercase">{{ FormMSG(4 + expenseItem.type, EXPENSE_NAME[expenseItem.type]) }}</span>
						#{{ expenseItem ? expenseItem.id : '' }}
					</div>
				</b-col>
				<b-col cols="4"> </b-col>
			</b-row>
		</template>
		<template #modal-footer>
			<div class="w-100 d-flex justify-content-end align-items-center">
				<b-button
					size="sm"
					variant="outline-primary"
					class="w-138-px"
					style="margin-top: 0px"
					:disabled="splittingItems.length === 1"
					@click="save"
					block
				>
					{{ FormMSG(2, 'Save') }}
				</b-button>
				<b-button size="sm" variant="custom-outline-gray" class="w-138-px ml-3" @click="emitEventClose" block>
					{{ FormMSG(1, 'Close') }}
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { EXPENSE_NAME, FLAG_ENTITY_ITEM_TYPE } from '@/shared/constants';
import CurrencySvg from '@/components/Svg/Currency';
import _ from 'lodash';
import Carousel from 'primevue/carousel';
import { getFileExtension } from '@/shared/helpers';
import Item from '@/components/ExpenseService/ExpenseSplittingItem';
import { rendCurrency } from '~helpers';
import { splitExpenseItem } from '@/cruds/expense.crud';
import { addUpdateProjectFlagEntity } from '@/cruds/flag.crud';
// import moment from 'moment';

export default {
	name: 'ExpenseSplittingModal',
	mixins: [LanguageMessages, GlobalMixin],
	components: {
		CurrencySvg,
		Carousel,
		Item
	},
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	props: {
		expenseId: {
			type: Number,
			required: true,
			default: 0
		},
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		expenseItem: {
			type: Object,
			required: false,
			default: null
		},
		index: {
			type: Number,
			required: false,
			default: 0
		},
		selectedCustomFlags: {
			type: Array,
			required: false,
			default: () => []
		}
	},
	data() {
		return {
			EXPENSE_NAME: EXPENSE_NAME,
			splittingItem: {
				amount: 0, // tax excl.
				amountVat: 0,
				amountTotal: 0, // tax incl.
				vatId: null
			},
			splittingItems: [],
			isValidLines: [],
			warningModal: false,
			global: {
				amount: 0,
				amountTotal: 0,
				amountVat: 0
			},
			globalValidation: {
				isValidAmount: true,
				isValidAmountTotal: true,
				isValidAmountVat: true
			},
			errorModal: false,
			numberItemVisible: 1,
			numberScroll: 1,
			originalExpenseItemVatId: null
		};
	},
	mounted() {
		this.splittingItems = [];
		this.isValidLines = [];
	},
	methods: {
		emitEventClose() {
			this.splittingItems = [];
			this.isValidLines = [];
			this.$emit('expense-splitting-modal:closed');
		},
		getImage(picture) {
			return process.env.VUE_APP_GQL + '/images/' + picture;
		},
		showPicture(data) {
			const images = this.expenseItem.images.map((img) => {
				return {
					xid: img,
					src: `${process.env.VUE_APP_GQL}/images/${img}`,
					thumb: process.env.VUE_APP_PATH_IMG_THUMB_URL + img,
					ext: getFileExtension(img)
				};
			});

			this.$previewImages({
				images,
				focusIndex: data.index,
				options: {
					presentationMode: 'doc',
					hideCommentButton: true,
					hideDisLikeButton: true,
					hideLikeDislike: true,
					hideCropButton: true,
					hideDeleteButton: true,
					showSignFileButton: false
				}
			});
		},
		removePicture(data) {
			const action = async () => {
				await this.$axios.$get(`/removeimage/${this.getFileName(this.expenseItem.images[data.index])}`).then((response) => {
					if (ERROR_STRING.ITEM_NOT_FOUND === response) {
						this.createToastForMobile(this.FormMSG(47, 'Error'), this.FormMSG(48, 'Image not found'), 'danger');
					} else {
						this.expenseItem.images.splice(data.index, 1);
					}
				});
			};

			this.confirmModal(action, this.FormMSG(44, 'Are you sure ?'), 'danger');
		},
		getFileName(filename) {
			return filename.substring(0, filename.lastIndexOf('.'));
		},
		prepareData() {
			this.splittingItem.amount = this.global.amount = parseFloat(this.expenseItem.amount);
			this.splittingItem.amountVat = this.global.amountVat = parseFloat(this.expenseItem.amountVat);
			this.splittingItem.amountTotal = this.global.amountTotal = parseFloat(this.expenseItem.amountTotal);
			this.splittingItem.vatId =
				!_.isUndefined(this.expenseItem.projectFlagItems) &&
				!_.isNil(this.expenseItem.projectFlagItems) &&
				this.expenseItem.projectFlagItems.taxeFlags.length > 0
					? this.expenseItem.projectFlagItems.taxeFlags[0].id
					: null;

			this.splittingItems.push(this.splittingItem);
			this.isValidLines.push(true);
		},
		currencyFormat(value) {
			return rendCurrency(parseFloat(value));
		},
		addSplittingItem() {
			let defaultItem = {
				amount: 0, // tax excl.
				amountVat: 0,
				amountTotal: 0, // tax incl.
				vatId: null
			};

			let defaultValidation = false;

			const { amount, amountTotal, amountVat } = this.calculateTotalAmount();

			defaultItem.amount = parseFloat((parseFloat(this.expenseItem.amount) - amount).toFixed(2));
			defaultItem.amountVat = parseFloat((parseFloat(this.expenseItem.amountVat) - amountVat).toFixed(2));
			defaultItem.amountTotal = parseFloat((parseFloat(this.expenseItem.amountTotal) - amountTotal).toFixed(2));
			defaultItem.vatId = this.originalExpenseItemVatId;

			this.splittingItems.push(defaultItem);

			if (defaultItem.amountTotal > 0 && defaultItem.amountTotal > defaultItem.amountVat) {
				defaultValidation = true;
			}

			// we need to recalculate
			const global = this.calculateTotalAmount();

			this.global.amount = global.amount;
			this.global.amountTotal = global.amountTotal;
			this.global.amountVat = global.amountVat;

			const globalValidation = this.isValidTotalAmount();
			this.globalValidation = globalValidation;
			// we need to recalculate

			this.isValidLines.push(defaultValidation);
		},
		onLineSplittingRemoved({ index }) {
			this.splittingItems.splice(index, 1);
			this.isValidLines.splice(index, 1);

			// we need to recalculate
			const global = this.calculateTotalAmount();

			this.global.amount = global.amount;
			this.global.amountTotal = global.amountTotal;
			this.global.amountVat = global.amountVat;

			const globalValidation = this.isValidTotalAmount();
			this.globalValidation = globalValidation;
			// we need to recalculate
		},
		onLineSplittingChanged({ index, item, isLineValid }) {
			this.isValidLines[index] = isLineValid;

			const { amount, amountTotal, amountVat } = this.calculateTotalAmount();

			this.global.amount = amount;
			this.global.amountTotal = amountTotal;
			this.global.amountVat = amountVat;

			const globalValidation = this.isValidTotalAmount();
			this.globalValidation = globalValidation;
		},
		calculateTotalAmount() {
			let amount = 0,
				amountTotal = 0,
				amountVat = 0;

			for (const item of this.splittingItems) {
				amount += parseFloat(parseFloat(item.amount).toFixed(2));
				amountTotal += parseFloat(parseFloat(item.amountTotal).toFixed(2));
				amountVat += parseFloat(parseFloat(item.amountVat).toFixed(2));
			}

			return { amount, amountTotal, amountVat };
		},
		validLines() {
			if (this.isValidLines.length === 0) return true;
			let isValidLines = this.isValidLines.join(',');

			return !/false/.test(isValidLines);
		},
		save() {
			if (!this.validLines()) {
				this.warningModal = true;

				return;
			}

			const { isValidAmount, isValidAmountTotal, isValidAmountVat } = this.isValidTotalAmount();
			if (!isValidAmount || !isValidAmountTotal || !isValidAmountVat) {
				this.errorModal = true;

				return;
			}

			/**
			 * Create && Update all lines
			 */
			const action = async () => {
				let splitDetails = [];
				for (const item of this.splittingItems) {
					const expense = {
						amount: parseFloat(parseFloat(item.amount).toFixed(2)),
						amountTotal: parseFloat(parseFloat(item.amountTotal).toFixed(2)),
						amountVat: parseFloat(parseFloat(item.amountVat).toFixed(2)),
						splitFlagItemId: !_.isNil(item.vatId) ? +item.vatId : 0
					};
					splitDetails.push(expense);
				}
				const payload = {
					originalExpenseItemId: +this.expenseItem.id,
					splitDetails
				};
				await splitExpenseItem(payload)
					.then(async (records) => {
						await this.updateExpenseItemsFlagItems(records);
						const message = this.FormMSG(22, 'All splitting expenses were created successfully, and you can find them at end of the list.');
						this.createToastForMobile(this.FormMSG(35, 'Success'), message, '', 'success');
						this.$emit('expense-splitting-modal:reload', { expenseId: this.expenseId, newExpenseItems: records, index: this.index });
					})
					.finally(() => {
						this.emitEventClose();
					});
			};

			this.confirmModal(
				action,
				this.FormMSG(21, `All lines (${this.splittingItems.length}) of splitting expenses seem correct, Are you sure to continue and save all lines ?`),
				'success'
			);
		},
		isValidTotalAmount() {
			const { amount, amountTotal, amountVat } = this.calculateTotalAmount();

			return {
				isValidAmount: parseFloat(parseFloat(this.expenseItem.amount).toFixed(2)) === parseFloat(amount.toFixed(2)),
				isValidAmountTotal: parseFloat(parseFloat(this.expenseItem.amountTotal).toFixed(2)) === parseFloat(amountTotal.toFixed(2)),
				isValidAmountVat: parseFloat(parseFloat(this.expenseItem.amountVat).toFixed(2)) === parseFloat(amountVat.toFixed(2))
			};
		},
		getFileExtension(fileName) {
			return getFileExtension(fileName);
		},
		async updateExpenseItemsFlagItems(expenseItems) {
			for (const item of expenseItems) {
				let flagEntityInputs = [];
				for (const flag of this.selectedCustomFlags) {
					if (!_.isNil(flag.item)) {
						flagEntityInputs.push({
							flagId: +flag.item.flagId,
							value: +flag.item.value,
							entityId: +item.id,
							type: FLAG_ENTITY_ITEM_TYPE.expense,
							factor: +flag.item.factor
						});
					}
				}

				await addUpdateProjectFlagEntity(+item.id, FLAG_ENTITY_ITEM_TYPE.expense, flagEntityInputs, false);
			}
		}
	},
	watch: {
		expenseItem: {
			handler(value) {
				if (!_.isNil(value)) {
					this.originalExpenseItemVatId =
						!_.isUndefined(value.projectFlagItems) && !_.isNil(value.projectFlagItems) && value.projectFlagItems.taxeFlags.length > 0
							? value.projectFlagItems.taxeFlags[0].id
							: null;
				}
			},
			deep: true,
			immediate: true
		}
	}
};
</script>
<style lang="scss">
#expense-splitting tbody tr td,
#expense-splitting .thead-dark tr th {
	border: 0.005rem solid rgba(226, 230, 235, 0.85) !important;
	border-collapse: collapse;
	font-size: 12px;
	letter-spacing: 0.05rem;
}

#expense-splitting tbody tr td {
	vertical-align: middle;
}

#expense-splitting tbody tr td .form-group {
	margin-bottom: 0;
}

#expense-splitting tfoot tr th {
	vertical-align: middle;
	border-right: 0.005rem solid rgba(226, 230, 235, 0.85);
	border-bottom: 0.005rem solid rgba(226, 230, 235, 0.85);
}

.text-bold {
	font-weight: 600;
	font-size: 1rem;
}

.text-color {
	font-size: 12px !important;
	color: rgba(6, 38, 62, 0.84) !important;
}

#expense-splitting-error-modal .header-class-modal-doc-package h5 {
	color: #fff;
}

.p-carousel {
	height: 100%;
}
</style>
