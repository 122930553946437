<template>
	<b-modal
		header-class="header-class-modal-doc-package"
		:title="FormMSG(1, 'Refuse this expense')"
		v-model="isOpen"
		size="md"
		centered
		no-close-on-backdrop
		no-close-on-esc
		hide-header-close
	>
		<div :class="$screen.width > 576 ? 'container-layout' : 'container-mobile'">
			<b-row>
				<b-col>
					<!-- <pre>{{ expenseId }}</pre> -->
					<b-form-group :label="FormMSG(2, 'Enter your comment')">
						<b-form-textarea
							v-model="comment"
							autocomplete="off"
							rows="3"
							:class="{
								'is-invalid': $v.comment.$error
							}"
						/>
						<div v-if="$v.comment.$error" class="invalid-feedback">
							{{ FormMSG(5, 'Please, comment is required') }}
						</div>
					</b-form-group>
				</b-col>
			</b-row>
		</div>
		<template #modal-footer>
			<div class="w-100 d-flex flex-row justify-content-end align-items-center">
				<b-button size="sm" variant="custom-outline-gray" class="w-138-px" :disabled="watingSubmitForm" @click="emitEventClose" block>
					{{ FormMSG(3, 'Close') }}
				</b-button>
				<b-button
					size="sm"
					variant="custom-primary-blue"
					class="w-138-px ml-2"
					:disabled="watingSubmitForm || $v.$invalid"
					style="margin-top: -1px"
					@click="save"
					block
				>
					<div class="d-flex justify-content-center align-items-center">
						<b-spinner v-show="watingSubmitForm" small />
						<div class="pl-2" style="margin-top: 1px">{{ FormMSG(4, 'Save') }}</div>
					</div>
				</b-button>
			</div>
		</template>
	</b-modal>
</template>
<script>
import LanguageMessages from '@/mixins/languageMessages';
import GlobalMixin from '@/mixins/global.mixin';
import { validationMixin } from 'vuelidate';
import { required, minLength } from 'vuelidate/lib/validators';
import { rejectExpense } from '@/cruds/expense.crud';
import { mapActions } from 'vuex';

export default {
	name: 'RejectExpenseModal',
	mixins: [LanguageMessages, GlobalMixin, validationMixin],
	computed: {
		isOpen: {
			get: function () {
				return this.value;
			},
			set: function (value) {
				return value;
			}
		}
	},
	props: {
		value: {
			type: Boolean,
			required: false,
			default: false
		},
		expenseId: {
			type: [Number, String],
			required: true,
			default: 0
		}
	},
	data() {
		return {
			watingSubmitForm: false,
			comment: ''
		};
	},
	mounted() {
		this.$v.$touch();
	},
	methods: {
		...mapActions({
			getNotifications: 'header/getNotifications'
		}),
		emitEventClose() {
			this.comment = '';
			this.$emit('reject-expense-modal:close');
		},
		async save() {
			this.watingSubmitForm = true;
			await rejectExpense(+this.expenseId, this.comment)
				.then(async (record) => {
					await this.getNotifications();
					this.comment = '';
					this.$emit('reject-expense-modal:rejected', record);
					this.watingSubmitForm = false;
				})
				.catch((error) => {
					this.comment = '';
					console.log(error);
					this.watingSubmitForm = false;
				});
		}
	},
	validations() {
		return {
			comment: {
				required,
				min: minLength(3)
			}
		};
	}
};
</script>
